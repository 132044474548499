/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { Routes, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import makeAnimated from 'react-select/animated';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Select from 'react-select';

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import { Post, Put, Get } from "../../tools/Helper";
import Message from "../../tools/Message";

function UserForm({ idEmpresa, editar, data, setShow, getLista, clientesArr, regiones }) {

  const [formData, setFormData] = useState(data);
  const [clientes, setClientes] = useState(clientesArr);
  const [loading, setLoading] = useState(false);
  const [loadingSuc, setLoadingSuc] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });

  const animatedComponents = makeAnimated();
  const [inputKey, setInputKey] = useState();
  const [arrRegiones, setArrRegiones] = useState([]);
  const [sucursales, setSucursales] = useState([]);


  const getSucursalesByRegion = async (infoRegiones) => {

    setSucursales([]);
    setStatus({ status: null, message: "" });


    if (infoRegiones !== undefined && infoRegiones.length === 0) {
      return
    }

    setLoadingSuc(true);
    const paramRegiones = infoRegiones.map(a => `region[]=${a.id_region}`).join('&');

    const request = await Get(`getSucursalesByRegion/?${paramRegiones}`);

    if (request.status === "success") {
      setSucursales(request.data);
      setStatus({ status: request.status, message: "Sucursales consultadas correctamente" });
    } else if (request.status === "empty") {
      setSucursales([]);
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoadingSuc(false);
  }

  useEffect(() => {

    setArrRegiones(regiones);

    setFormData({
      ...data,
      admin: 0,
    });

    setClientes(clientesArr);
    setStatus({ status: null, message: "" });

    if (editar) {
      getSucursalesByRegion(data.regiones);
    }

  }, [data, setFormData]);

  const handleChange = (e) => {

    if (e.target && (e.target.name === 'telefono_celular' ||
      e.target.name === 'telefono_empresarial') &&
      e.target.value.split("").length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleChangeSelect = (e, name, value) => {

    if (name === "regiones") {
      getSucursalesByRegion(e);
    }
    setFormData({ ...formData, [value]: e[value], [name]: e });

  }

  const doRequest = async e => {

    setStatus({ status: null, message: "" });

    if (!formData.cliente) {
      setStatus({ status: 'error', message: "Debe seleccionar un cliente o 'No Aplica'" });
      return;
    }

    setLoading(true);

    const tempData = formData;
    const request = editar ? await Put(`users/${formData.id}`, formData) : await Post('users', formData);

    if (request.status === "success") {
      await getLista();
      await getLista();
      setShow(true);
    }
    setStatus(request);
    setLoading(false);
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }


  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const locationButton = (row) => <Tooltip title="Ubicación"><MDButton className="btnPaymun btnChangeStatus" iconOnly ><Icon>not_interested</Icon></MDButton></Tooltip>;

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
      compact: true,
      grow: 0,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
    },
    {
      name: 'Calle y número',
      selector: row => row.calle,
    },
    {
      name: 'Colonia',
      selector: row => row.colonia,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
      compact: true,
      grow: 0,
    },
    {
      name: 'Ubicación',
      selector: row => locationButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
  ];

  return (
    <MDBox pb={3}>
      <Card mt={4}>
        <MDBox p={3} className="headerFormPymn">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {editar ? " Actualizar" : " Agregar"} usuario externo
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              : <>

                <Grid item xs={12} mt="auto">
                  <MDInput type="text" required label="Nombre o Alias" name="name" disabled={loading}
                    error={verifyError("name")} errorMsg={getError("name")}
                    value={formData.name} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDInput type="text" required label="Telefono Empresarial" name="telefono_empresarial" disabled={loading}
                    error={verifyError("telefono_empresarial")} errorMsg={getError("telefono_empresarial")}
                    value={formData.telefono_empresarial} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Telefono Celular" name="telefono_celular" disabled={loading}
                    error={verifyError("telefono_celular")} errorMsg={getError("telefono_celular")}
                    value={formData.telefono_celular} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDInput type="email" label="Correo Empresarial" name="email" required disabled={loading}
                    error={verifyError("email")} errorMsg={getError("email")}
                    inputProps={{ maxLength: "99" }}
                    value={formData.email} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDInput type="email" label="Correo Personal" name="correo_personal" disabled={loading}
                    error={verifyError("correo_personal")} errorMsg={getError("correo_personal")}
                    inputProps={{ maxLength: "99" }}
                    value={formData.correo_personal} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item xs={6} mb="auto">
                  <Select className={`z-10 ${verifyError("regiones") ? "border-red" : ""}`}
                    closeMenuOnSelect
                    components={animatedComponents}
                    key={inputKey || ''}
                    isMulti placeholder="Regiones"
                    value={formData.regiones} onChange={(e) => handleChangeSelect(e, "regiones", "arrRegiones")}
                    options={arrRegiones}
                    getOptionValue={(option) => `${option.id_region}`}
                    getOptionLabel={(option) => `${option.nombre}`}
                  />
                </Grid>

                <Grid item xs={6} mb="auto">
                  <Select required className={`z-10 ${verifyError("id_cliente") ? "border-red" : ""}`}
                    options={clientes} placeholder="Nombre del cliente..."
                    value={formData.cliente} onChange={(e) => handleChangeSelect(e, "cliente", "id_cliente")}
                    getOptionValue={(option) => `${option.id_cliente}`}
                    getOptionLabel={(option) => `${option.nombre_comercial}`}
                    isDisabled={(idEmpresa !== undefined)}
                  />
                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDTypography variant="caption" pt={3} pr={3}>Contraseña</MDTypography>
                  <MDInput type="text" label="Contraseña*" name="password" disabled={loading}
                    error={verifyError("password")} errorMsg={getError("password")}
                    value={formData.password} onChange={event => handleChange(event)} fullWidth />
                  <MDTypography variant="caption" pt={3} fontWeight="bold">*Dejar en blanco si no desea editar la contraseña</MDTypography>
                </Grid>

                {loadingSuc ?

                  <Grid item xs={12} mt="auto" display="block" fullWidth textAlign="center" p={5}  >
                    <CircularProgress size="3rem" />
                    <MDTypography variant="h6" pt={3}>Cargando sucursales...</MDTypography>
                  </Grid>

                  : null
                }
                {!loadingSuc &&
                  (sucursales !== undefined &&
                    sucursales.length > 0) ?
                  <>
                    <Grid item xs={12} mt="auto" display="block" fullWidth >
                      <MDTypography variant="h6" pt={3}>Sucursales asignadas</MDTypography>
                    </Grid>
                    <Grid item xs={12} mt="auto">
                      <DataTable
                        columns={columns}
                        data={sucursales}
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: 'Sucursales por página:',
                          rangeSeparatorText: 'de',
                          noRowsPerPage: false,
                          selectAllRowsItem: true,
                          selectAllRowsItemText: 'Todos'
                        }}
                        noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />
                    </Grid>
                  </>
                  : null
                }

                <Grid container mt={.5} spacing={4}>
                  <Grid item md={4} xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <MDButton disabled={loading}
                      className="btnPaymun"
                      onClick={() => doRequest()} fullWidth >
                      <Icon fontSize="small">done</Icon>
                      &nbsp;{editar ? "Actualizar" : "Agregar"}

                    </MDButton>
                  </Grid>

                  <Grid item md={2} xs={12}>
                    <MDButton
                      onClick={() => setShow(true)}
                      className="btnBackPaymun" fullWidth>
                      <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar
                    </MDButton>
                  </Grid>

                </Grid>

              </>
            }

          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

UserForm.propTypes = {
  idEmpresa: PropTypes.number.isRequired,
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  clientesArr: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  regiones: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default UserForm;