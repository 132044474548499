/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// leaflet maps
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from 'react-modal';
import Tooltip from '@mui/material/Tooltip';

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import makeAnimated from 'react-select/animated';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DataTable from 'react-data-table-component';

import imageCompression from 'browser-image-compression';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Divider from "@mui/material/Divider";
import { Post, Put, PostGuest, Get } from "../../../tools/Helper";
import Message from "../../../tools/Message";
import { selectStyles } from '../../../tools/utils';

function ClienteForm({ data, consulta, editar, setShow, getLista, isExternal = false, listRegime }) {

  const center = [52.22977, 21.01178];

  const points = [
    {
      lat: 52.230020586193795,
      lng: 21.01083755493164,
      title: 'point 1'
    },
    {
      lat: 52.22924516170657,
      lng: 21.011320352554325,
      title: 'point 2'
    },
    {
      lat: 52.229511304688444,
      lng: 21.01270973682404,
      title: 'point 3'
    },
    {
      lat: 52.23040500771883,
      lng: 21.012146472930908,
      title: 'point 4'
    },
  ];

  const MyMarkers = ({ info }) => info.map(({ lat, lng, title }) => (
    <Marker
      key={`${lat}-${lng}`}
      position={{ lat, lng }}
    >
      <Popup>{title}</Popup>
    </Marker>
  ))

  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [coloniasFiscales, setColoniasFiscales] = useState([]);
  const [coloniasInmueble, setColoniasInmueble] = useState([]);
  const [valueTab, setValueTab] = useState('0');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState(0);
  const [sucursales, setSucursales] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [regiones, setRegiones] = useState([]);

  const [titleTabSuc, setTitleTabSuc] = useState("Sucursales");
  const [titleTabResp, setTitleTabResp] = useState("Responsables");
  const [titleTabRegion, setTitleTabRegion] = useState("Regiones");

  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      width: '50%'
    },
  };

  const buscarCodigoPostal = async (id, postalCode) => {

    if (!postalCode && (id === 'cp_fiscal' && !formData.cp_fiscal)) {
      return;
    }

    setStatus({ status: null, message: "" });
    setLoading(true);

    let cp = '';

    if (id === 'cp_fiscal') {

      setColoniasFiscales([]);

      if (postalCode) {
        cp = postalCode;
      }
      else {
        setFormData({
          ...formData,
          estado_fiscal: '',
          municipio_fiscal: '',
          id_colonia_fiscal: 0,
        });

        cp = formData.cp_fiscal;
      }

    } else {

      setColoniasInmueble([]);

      if (postalCode) {
        cp = postalCode;
      }
      else {
        cp = formData.cp_inmueble;

        setFormData({
          ...formData,
          estado_inmueble: '',
          municipio_inmueble: '',
          id_colonia_inmueble: 0,
        });

      }
    }

    const request = await Get(`getByPostalCode/${cp}`);

    if (request.status === "success") {

      if (request.data.length > 0) {

        if (id === 'cp_fiscal') {
          setColoniasFiscales(request.data);

          if (!postalCode) {
            setFormData({
              ...formData,
              estado_fiscal: request.data[0].nombreEstado,
              municipio_fiscal: request.data[0].nombreMunicipio,
            });
          }

        } else {

          setColoniasInmueble(request.data);

          if (!postalCode) {
            setFormData({
              ...formData,
              estado_inmueble: request.data[0].nombreEstado,
              municipio_inmueble: request.data[0].nombreMunicipio,
            });
          }
        }
      }

      setStatus({ status: request.status, message: "Se ha realizado la búsqueda del Código Postal exitosamente." });

      setTimeout(() => {
        setStatus({ status: null, message: "" });
      }, 3500);

    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  const getData = async (dataCliente) => {

    setLoading(true);
    setStatus({ status: null, message: "" });

    const request = await Get(`cliente-externo-by-id/${data.id_cliente}`);

    if (request.status === "success") {
      setFormData(request.data[0]);
      setSucursales(request.data[0].sucursales);
      setResponsables(request.data[0].responsables);
      setRegiones(request.data[0].regiones);

      setTitleTabSuc(`Sucursales (${request.data[0].sucursales.length})`);
      setTitleTabResp(`Responsables (${request.data[0].responsables.length})`);
      setTitleTabRegion(`Regiones (${request.data[0].regiones.length})`);

    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  };

  useEffect(async () => {

    setFormData(data);

    if (data.id_cliente !== undefined) {
      await getData(data);
      await buscarCodigoPostal('cp_fiscal', data.cp_fiscal);
      await buscarCodigoPostal('cp_inmueble', data.cp_inmueble);

    }

    setStatus({ status: null, message: "" });

  }, [data, setFormData]);

  const handleChange = (e) => {

    if (e.target.name === 'cp_fiscal') {

      setFormData({
        ...formData,
        estado_fiscal: '',
        municipio_fiscal: '',
      });

    }

    setFormData({ ...formData, [e.target.name]: e.target.value });

  }

  const handleChangeSelect = (e, name = "", value = "") => {

    if (value === 'id_colonia_fiscal') {

      const codigoPostalFiscal = coloniasFiscales.find(option => option.idColonia === e[name]);

      setFormData({
        ...formData,
        colonia_fiscal: codigoPostalFiscal.nombreColonia,
        [value]: e[name]
      });

    } else if (value === 'id_colonia_inmueble') {

      const codigoPostalInmueble = coloniasInmueble.find(option => option.idColonia === e[name]);

      setFormData({
        ...formData,
        colonia_inmueble: codigoPostalInmueble.nombreColonia,
        [value]: e[name]
      });

    } else {
      setFormData({
        ...formData,
        [value]: e[name]
      });
    }
  }

  const handleFile = async (event) => {
    setStatus({
      status: 'warning',
      message: "Comprimiendo imagen...",
    });
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 720,
      useWebWorker: true,
    }

    try {
      const compressedFile = await imageCompression(imageFile, options);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = function () {
        const imgBase64data = reader.result;
        setFormData({
          ...formData,
          logo_cliente: imgBase64data,
        });
        setStatus({
          status: null,
          message: "",
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  const deleteLogo = () => {
    setFormData({
      ...formData,
      logo_cliente: '',
    });
  }

  const showModuleResp = () => {
    setIsOpen(false);
    navigate(`/responsables-empresa/${idCliente}`);
  }

  const doRequest = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const tempData = formData;
    let request;

    if (editar) {
      request = await Put(`clientes/${formData.id_cliente}`, formData)
    } else if (userData) {
      request = await Post('clientes', formData)
    } else {
      request = await PostGuest('cliente-invitado', formData);
    }

    if (request.status === "success") {

      setStatus(request);
      setLoading(false);

      if (editar) {
        await getLista();
        setShow(true);
        return;
      }

      setIdCliente(request.data.id_cliente);
      setIsOpen(true);
      return;

    }

    setStatus({
      ...request,
      status: 'error'
    });

    setLoading(false);
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const keyPressCP = (e) => {

    if (e.target.name === 'cp_fiscal') {

      setColoniasFiscales([]);

      setFormData({
        ...formData,
        estado_fiscal: '',
        municipio_fiscal: '',
        id_colonia_fiscal: 0
      });

      if (e.charCode === 13) {
        buscarCodigoPostal(e.target.name);
      }
    } else {

      setColoniasInmueble([]);

      setFormData({
        ...formData,
        estado_inmueble: '',
        municipio_inmueble: '',
        id_colonia_inmueble: 0
      });

      if (e.charCode === 13) {
        buscarCodigoPostal(e.target.name);
      }

    }

    // no permite los caracteres
    // - y +
    if (e.charCode === 45 ||
      e.charCode === 43 ||
      e.charCode === 45
    ) {
      e.preventDefault();
    }

    // si es cero y no hay informacion
    // retorna el evento
    if (e.charCode === 48 && e.target.value.length === 0) {
      e.preventDefault();
    }

    if (e.target.value.length > 4) {
      e.preventDefault();
    }

  };

  const getRegime = listRegime.find(option => option.key === formData.regimen_fiscal);
  const getCodigoPostalFiscal = coloniasFiscales.find(option => option.idColonia === formData.id_colonia_fiscal);
  const getCodigoPostalInmueble = coloniasInmueble.find(option => option.idColonia === formData.id_colonia_inmueble);
  const locationButton = (row) => <Tooltip title="Ubicación"><MDButton className="btnPaymun btnChangeStatus" iconOnly ><Icon>not_interested</Icon></MDButton></Tooltip>;

  const getTitle = () => {

    if (consulta) {
      return "Consulta";
    }

    if (editar) {
      return "Actualizar";
    }

    if (!editar) {
      return "Agregar";
    }

    return "";

  }

  const columnsSucursales = [
    {
      name: 'Clave',
      selector: row => row.clave,
      compact: true,
      grow: 0,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
    },
    {
      name: 'Calle y número',
      selector: row => row.calle,
    },
    {
      name: 'Colonia',
      selector: row => row.colonia,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
      compact: true,
      grow: 0,
    },
    {
      name: 'Ubicación',
      selector: row => locationButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
  ];

  const columnsResponsables = [
    {
      name: 'Región',
      selector: row => row.regiones.map((reg) => `${reg.nombre}`).join(', '),
      compact: true,
      wrap: true
    },
    {
      name: 'Responsable',
      selector: row => row.name,
    },
    {
      name: 'Teléfono empresarial',
      selector: row => row.telefono_empresarial,
    },
    {
      name: 'Teléfono celular',
      selector: row => row.telefono_celular,
    },
    {
      name: 'Correo empresarial',
      selector: row => row.email,
    },
    {
      name: 'Correo personal',
      selector: row => row.correo_personal,
      compact: true,
      grow: 0,
    }
  ];

  const columnsRegiones = [
    {
      name: 'Clave',
      selector: row => row.clave,
      compact: true,
      grow: 0,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
    }
  ];

  return (
    <MDBox pb={3}>
      <Card mt={4}>
        <MDBox p={3}
          className="headerFormPymn"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {getTitle()} Empresa
              </MDTypography>
            </Grid>

          </Grid>
        </MDBox>
        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2} pt={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              :
              <>

                <Grid item xs={2} mt="auto" pt={.5} mr={2} >
                  {
                    formData.logo_cliente ?
                      <div>
                        <img alt="logo" width={150} src={formData.logo_cliente} className="imgLogoCliente" />
                        {!consulta ?
                          <MDButton variant="gradient" color="error" disabled={loading || consulta}
                            onClick={() => deleteLogo()} fullWidth>
                            Quitar Logo
                          </MDButton>
                          : null
                        }

                      </div>
                      :
                      <div>
                        <div id="src-logo" className='file-select'>
                          <input
                            disabled={loading || consulta}
                            type='file' accept="image/*"
                            onChange={event => handleFile(event)}
                          />
                        </div>
                        <div>
                          <p className="txtInfoLogo">Alto: 150px - Ancho: 150px</p>
                        </div>
                      </div>

                  }
                </Grid>

                <Grid container xs={10} spacing={2}>

                  <Grid item xs={12} mt="auto" style={{ display: 'flex', justifyContent: 'center' }} >
                    <MDInput type="text" label="Razón Social" name="razon_social" disabled={loading || consulta}
                      error={verifyError("razon_social")} errorMsg={getError("razon_social")}
                      value={formData.razon_social} onChange={event => handleChange(event)} fullWidth
                      required />
                  </Grid>

                  <Grid item xs={6} mt="auto">
                    <MDInput type="text" label="Nombre Comercial" name="nombre_comercial" disabled={loading || consulta}
                      error={verifyError("nombre_comercial")} errorMsg={getError("nombre_comercial")}
                      value={formData.nombre_comercial} onChange={event => handleChange(event)} fullWidth
                      required />
                  </Grid>
                  <Grid item xs={6} mt="auto">
                    <MDInput type="text" label="RFC" name="rfc" disabled={loading || consulta}
                      error={verifyError("rfc")} errorMsg={getError("rfc")}
                      value={formData.rfc} onChange={event => handleChange(event)} fullWidth
                      required />
                  </Grid>
                  <Grid item xs={6} mt="auto">
                    <MDInput type="text" label="Representante legal" name="nombre_patron" disabled={loading || consulta}
                      error={verifyError("nombre_patron")} errorMsg={getError("nombre_patron")}
                      value={formData.nombre_patron} onChange={event => handleChange(event)} fullWidth />
                  </Grid>
                  <Grid item xs={6} mt="auto">
                    <MDInput type="text" label="Representante de los trabajadores" name="nombre_representante" disabled={loading || consulta}
                      error={verifyError("nombre_representante")} errorMsg={getError("nombre_representante")}
                      value={formData.nombre_representante} onChange={event => handleChange(event)} fullWidth />
                  </Grid>
                </Grid>

                <Grid item xs={12} mt="auto">
                  <hr className="MuiDivider-root MuiDivider-fullWidth css-4g2ewc-MuiDivider-root" />
                </Grid>

                <Grid item xs={12} mt="auto">
                  Domicilio fiscal:
                </Grid>

                <Grid item xs="2" mt="auto" mr={1.5}>
                  <Grid container>
                    <Grid item xs={10} >
                      <MDInput type="number" label="Código postal" name="cp_fiscal" disabled={loading || consulta}
                        error={verifyError("cp_fiscal")} errorMsg={getError("cp_fiscal")}
                        value={formData.cp_fiscal} onChange={event => handleChange(event)}
                        onKeyPress={keyPressCP} fullWidth required />
                    </Grid>
                    {!consulta ?
                      <Grid item xs={1} mt={.4} >
                        <MDButton className="btnPaymun btnSearch" iconOnly
                          size="medium" onClick={() => buscarCodigoPostal('cp_fiscal')} >
                          <Icon>search</Icon>
                        </MDButton>
                      </Grid>
                      : null
                    }
                  </Grid>

                </Grid>

                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Estado" name="estado_fiscal" disabled={loading || consulta}
                    error={verifyError("estado_fiscal")} errorMsg={getError("estado_fiscal")}
                    value={formData.estado_fiscal} onChange={event => handleChange(event)}
                    inputProps={{ readOnly: true, }} fullWidth />
                </Grid>

                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Municipio/Alcaldía" name="municipio_fiscal" disabled={loading || consulta}
                    error={verifyError("municipio_fiscal")} errorMsg={getError("municipio_fiscal")}
                    value={formData.municipio_fiscal} onChange={event => handleChange(event)}
                    inputProps={{ readOnly: true, }} fullWidth />
                </Grid>

                <Grid item xs={3} mt="auto">
                  <Select
                    className={`z-10 ${verifyError("id_colonia_fiscal") ? "border-red" : ""}`}
                    closeMenuOnSelect
                    menuPortalTarget={document.body} styles={selectStyles}
                    options={coloniasFiscales} placeholder="Colonia" isDisabled={loading || consulta}
                    value={getCodigoPostalFiscal || ''} onChange={(e) => handleChangeSelect(e, "idColonia", "id_colonia_fiscal")}
                    getOptionValue={(option) => `${option.idColonia}`}
                    getOptionLabel={(option) => `${option.nombreColonia}`}
                  />
                </Grid>

                <Grid item xs={5} mt="auto">
                  <MDInput type="text" label="Calle" name="calle_num_fiscal" disabled={loading || consulta}
                    error={verifyError("calle_num_fiscal")} errorMsg={getError("calle_num_fiscal")}
                    value={formData.calle_num_fiscal} onChange={event => handleChange(event)} fullWidth
                    required />
                </Grid>
                <Grid item xs={2} mt="auto">
                  <MDInput type="text" label="Número" name="num_fiscal" disabled={loading || consulta}
                    error={verifyError("num_fiscal")} errorMsg={getError("num_fiscal")}
                    value={formData.num_fiscal} onChange={event => handleChange(event)} fullWidth
                    required />
                </Grid>
                <Grid item xs={5} mt="auto">
                  <Select
                    className={`z-101 ${verifyError("regimen_fiscal") ? "border-red" : ""}`}
                    options={listRegime} placeholder="Régimen Fiscal" isDisabled={loading || consulta}
                    value={getRegime || ''} onChange={(e) => handleChangeSelect(e, "key", "regimen_fiscal")}
                    getOptionValue={(option) => `${option.key}`}
                    getOptionLabel={(option) => `${option.name}`}
                  />
                </Grid>

                <Grid item xs={12} mt="auto">
                  <hr className="MuiDivider-root MuiDivider-fullWidth css-4g2ewc-MuiDivider-root" />
                </Grid>

                <Grid item xs={12} mt="auto">
                  Dirección del inmueble:
                </Grid>

                <Grid item xs={2} mt="auto" mr={1.5}>
                  <Grid container>
                    <Grid item xs={10} >
                      <MDInput type="text" label="Código postal" name="cp_inmueble" disabled={loading || consulta}
                        error={verifyError("cp_inmueble")} errorMsg={getError("cp_inmueble")}
                        value={formData.cp_inmueble} onChange={event => handleChange(event)}
                        required onKeyPress={keyPressCP} />
                    </Grid>
                    {!consulta ?
                      <Grid item xs={2} mt={.4} >
                        <MDButton className="btnPaymun btnSearch" iconOnly
                          size="medium" onClick={() => buscarCodigoPostal('cp_inmueble')} >
                          <Icon>search</Icon>
                        </MDButton>
                      </Grid>
                      : null
                    }

                  </Grid>
                </Grid>
                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Estado" name="estado_inmueble" disabled={loading || consulta}
                    error={verifyError("estado_inmueble")} errorMsg={getError("estado_inmueble")}
                    value={formData.estado_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Municipio/Alcaldía" name="municipio_inmueble" disabled={loading || consulta}
                    error={verifyError("municipio_inmueble")} errorMsg={getError("municipio_inmueble")}
                    value={formData.municipio_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={3} mt="auto">
                  <Select
                    className={`z-10 ${verifyError("id_colonia_inmueble") ? "border-red" : ""}`}
                    closeMenuOnSelect
                    menuPortalTarget={document.body} styles={selectStyles}
                    options={coloniasInmueble} placeholder="Colonia" isDisabled={loading || consulta}
                    value={getCodigoPostalInmueble || ''} onChange={(e) => handleChangeSelect(e, "idColonia", "id_colonia_inmueble")}
                    getOptionValue={(option) => `${option.idColonia}`}
                    getOptionLabel={(option) => `${option.nombreColonia}`}
                  />

                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Calle y número" name="calle_num_inmueble" disabled={loading || consulta}
                    error={verifyError("calle_num_inmueble")} errorMsg={getError("calle_num_inmueble")}
                    value={formData.calle_num_inmueble} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                {(editar || consulta) ?

                  <Grid item md={12} xs={12} >
                    <Tabs
                      value={valueTab}
                      textColor="primary"
                      indicatorColor="primary"
                      onChange={(event, newValue) => {
                        setValueTab(newValue);
                      }}
                    >
                      <Tab label={titleTabRegion} value="0" />
                      <Tab label={titleTabResp} value="1" />
                      <Tab label={titleTabSuc} value="2" />
                    </Tabs>

                    <Grid md={12} pt={3} pb={3} spacing={6}>
                      {valueTab === "0" ?
                        <Grid item xs={12} mt="auto">
                          <DataTable
                            columns={columnsRegiones}
                            data={regiones}
                            pagination
                            paginationComponentOptions={{
                              rowsPerPageText: 'Regiones por página:',
                              rangeSeparatorText: 'de',
                              noRowsPerPage: false,
                              selectAllRowsItem: true,
                              selectAllRowsItemText: 'Todos'
                            }}
                            noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                          />
                        </Grid>

                        :
                        null
                      }

                      {valueTab === "1" ?
                        <Grid item xs={12} mt="auto">
                          <DataTable
                            columns={columnsResponsables}
                            data={responsables}
                            pagination
                            paginationComponentOptions={{
                              rowsPerPageText: 'Responsables por página:',
                              rangeSeparatorText: 'de',
                              noRowsPerPage: false,
                              selectAllRowsItem: true,
                              selectAllRowsItemText: 'Todos'
                            }}
                            noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                          />
                        </Grid>

                        :
                        null
                      }
                      {valueTab === "2" ?
                        <Grid item xs={12} mt="auto" height={300}>
                          <MapContainer center={center} zoom={18} height={300} scrollWheelZoom={false}>

                            <MyMarkers info={points} />

                          </MapContainer>
                        </Grid>
                        : null}
                    </Grid>

                  </Grid>
                    :
                  null
                }

                <Grid container md={12} spacing={2} mt={1} >

                  <Grid item md={4} >
                    &nbsp;
                  </Grid>

                  {!consulta ?
                    <Grid item md={2} >
                      <MDButton disabled={loading}
                        className="btnPaymun"
                        onClick={() => doRequest()} >
                        {editar ? "Actualizar" : "Agregar"}
                      </MDButton>
                    </Grid>
                    :
                    null
                  }
                  <Grid item md={2}>
                    <MDButton
                      onClick={() => setShow(true)}
                      className="btnBackPaymun" >
                      <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar
                    </MDButton>
                  </Grid>
                </Grid>


              </>
            }
          </Grid>
        </MDBox>

      </Card>

      <Modal
        isOpen={modalIsOpen}
        contentLabel="Al crear una nueva empresa se debe de crear un responsable, por favor registralo a continuación."
        style={customStyles}
      >
        <Grid container spacing={1} p={1}>
          <Grid item xs={12} pb={2}>
            <MDTypography variant="h5">Al crear una nueva empresa se debe de crear un responsable, por favor registralo a continuación.</MDTypography>
          </Grid>
          <Grid item xs={4}>&nbsp;</Grid>
          <Grid item xs={4}>
            <MDButton className="btnPaymun" disabled={loading || consulta}
              onClick={() => showModuleResp()}
              fullWidth>
              Registrar responsable
            </MDButton>
          </Grid>
        </Grid>
      </Modal>

    </MDBox>
  );
}

ClienteForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  consulta: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  isExternal: PropTypes.bool.isRequired,
  listRegime: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default ClienteForm;
