
import React, { useState, useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import { USER_ADMIN, USER_INSTRUCTOR, USER_LIDER_CAPACITACION } from "constans";
import {Put, Post} from "../../tools/Helper";
import Message from "../../tools/Message";
import Detalles from './Detalles';
import Direccion from './Direccion';
import Participantes from './Participantes';
import Reportes from "./Reportes";

function Programacion( { setIsEditing, isEditing, dataDetCurso, setShow, cursantesProgramados, administradores, clientes, cursos, instructores }) {
  const navigate = useNavigate();
  const scrollRef = useRef(React.createRef());
  const [valueTab, setValueTab] = useState(0);
  const [status, setStatus] = useState({status: null, message: ""});
  const [loading, setLoading] = useState(false);
  const [enableTab, setEnableTabs] = useState(false);
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;

  const emptyForm = {
    nombre_comercial: '',
    programa_interno: '',
    nombre_curso: '',
    duracion_curso: '',
    num_participantes: 0,
    fecha_imparticion_curso: datestring,
    fecha_termino: datestring,
    fecha_fin: datestring,
    vigencia_str: null,
    vigencia_fecha: datestring,
    id_cliente: '',
    cliente:{
      nombre_comercial: '',
      latitude: '',
      longitude: '',
    },
    calle_numero: '',
    colonia: '',
    municipio_alcaldia: '',
    estado: '',
    cursantes: [],
  };

  const [dataCursoProgramado, setDataCursoProgramado] = useState(emptyForm);

  const submitProgramarCurso = async () =>{
    const tempData = dataCursoProgramado;
    if(! tempData.programa && ! isEditing){
      setStatus({status: 'error', message: "Seleccione SI o NO en programa interno"});
      console.log(tempData)
      return;
    }
    setStatus({status: 'warning', message: "Guardando...."});
    setLoading(true);
    tempData.nombre_comercial = tempData.cliente.nombre_comercial;
    let request; 
    if(isEditing){
      request = await Put(`programar-curso/${tempData.id_curso_programado}`,tempData);
      if(request.status === 'success'){
        setEnableTabs(true);
        setEditing(false);
        setIsEditing(true);

      }
    }else{
      request = await Post('programar-curso',tempData);
      if(request.status === 'success'){
        navigate('/cursos/programacion-cursos');
        setEnableTabs(true);
      }
    }
    setStatus(request);

    if(scrollRef.current){
      scrollRef.current.scrollIntoView({ 
           behavior: "smooth", 
        });
    }
    setLoading(false);
  }

  useEffect(()=>{

    console.log('dataDetCurso', dataDetCurso);
    
    setValueTab(0);
    if(isEditing) {
      setEditing(false);
      setDataCursoProgramado({
        ...dataDetCurso,
        fecha_imparticion_curso: dataDetCurso.fecha_inicio,
        fecha_termino: dataDetCurso.fecha_fin,
        respuesta: dataDetCurso.programa_interno,
        comentario_general: dataDetCurso.comentario_general,
        programa_interno: dataDetCurso.programa_interno,
        cliente:{
          nombre_comercial: dataDetCurso.nombre_comercial,
          latitude: dataDetCurso.latitude,
          longitude: dataDetCurso,
        },
        cursantes: [],
      })
      return;
    }
    setEditing(true);
  },[isEditing]);

   return (
    <MDBox pb={3} ref={scrollRef}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                  variant="gradient" bgColor="info"
                  borderRadius="lg" coloredShadow="info"
                >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Programación de curso
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton variant="gradient" color="light" style={{ borderRadius: 5, paddingLeft: 15, paddingRight: 15 }}
                      onClick={()=>{
                        navigate('/cursos/programacion-cursos')
                        }}>
                      <Icon fontSize="small">arrow_back_ios</Icon>
                      &nbsp;Regresar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid pb={3}>
                      <Tabs
                          value={valueTab}
                          textColor="primary"
                          indicatorColor="primary"
                          onChange={(event, newValue) => {
                              setValueTab(newValue);
                          }}
                      >
                          <Tab label="Detalles" />
                          <Tab label="Dirección" />
                          <Tab label="Participantes" disabled ={!enableTab && !isEditing  ? 'false' : null} />
                          <Tab label="Reportes" disabled ={!enableTab && !isEditing ? 'false' : null} /> 
                      </Tabs>
                      <Grid pt={3} pb={3} spacing={6}>
                        { valueTab === 0 ? <Detalles 
                          editing = {editing} 
                          dataCursoProgramado = {dataCursoProgramado} 
                          setDataCursoProgramado = {setDataCursoProgramado} 
                          administradores = {administradores}
                          clientes = {clientes}
                          cursos = {cursos}
                          instructores= {instructores}
                          /> : null }
                        { valueTab === 1 ? <Direccion editing = {editing} dataCursoProgramado = {dataCursoProgramado} setDataCursoProgramado = {setDataCursoProgramado} /> : null }
                        { valueTab === 2 ? <Participantes 
                          dataCursoProgramado = {dataCursoProgramado} 
                          setDataCursoProgramado = {setDataCursoProgramado} 
                          cursantesProgramados = {cursantesProgramados}
                          /> : null 
                        }
                        { valueTab === 3 ? <Reportes dataCursoProgramado = {dataCursoProgramado}  setDataCursoProgramado = {setDataCursoProgramado} setShow = {setShow} setValueTab = {setValueTab} /> : null }
                        
                      </Grid>
                    </Grid>
                    <Message status={status} />
                    {
                      dataDetCurso.estatus === 'Completado' ?
                        <MDButton variant="gradient" color="dark" style={{ color: 'white', borderRadius: 5 }}
                          fullWidth >
                          CURSO CERRADO
                        </MDButton>
                      :
                    <Grid display="flex" justifyContent="space-between" alignItems="center" item xs={12}>
                      {
                        (userData &&  parseInt(userData.user.admin, 10) === 1) || (userData && parseInt(userData.user.admin, 10) === USER_LIDER_CAPACITACION) ?
                          <Grid item xs={12}>
                            {
                              isEditing ?
                              <MDBox mt={4} mb={1}>
                                {
                                  !editing ?
                                    <MDButton variant="gradient" color="warning" style={{ color: 'white', borderRadius: 5 }}
                                      onClick={() => setEditing(true)} 
                                      fullWidth >
                                      EDITAR CURSO
                                    </MDButton>
                                  :
                                    <MDButton variant="gradient" color="info" style={{ color: 'white', borderRadius: 5 }}
                                    onClick={() => submitProgramarCurso()} 
                                    fullWidth >
                                    GUARDAR CURSO
                                    </MDButton>
                                }
                              </MDBox>
                              : 
                              <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" style={{ color: 'white', borderRadius: 5 }}
                                  onClick={() => submitProgramarCurso()} 
                                  fullWidth >
                                  PROGRAMAR CURSO
                                </MDButton>
                              </MDBox>
                            }
                          </Grid>
                        : null
                      }
                    </Grid>
                    }
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
    </MDBox>
  );
}

Programacion.propTypes = {
    dataDetCurso: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    isEditing: PropTypes.bool.isRequired,
    setIsEditing: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    cursantesProgramados: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    administradores: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    clientes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    cursos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    instructores: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default Programacion;
