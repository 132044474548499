/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";
import makeAnimated from 'react-select/animated';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Select from 'react-select';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import { NumericFormat } from 'react-number-format';

import PropTypes from 'prop-types';
import { parseCurrency } from "tools/utils";
import { Post, Put, Get } from "../../tools/Helper";
import Message from "../../tools/Message";
import ListaInsumosServ from "./ListInsumosServ";

function ServicioForm({ editar, data, setShow, getLista, tipos, departamentos, responsables, unidadesArr, triggerEdit, setTriggerEdit }) {

  const [inputKey, setInputKey] = useState();
  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });

  const [arrResponsables, setArrResponsables] = useState([]);
  const [permiteEditDesc, setPermiteEditDesc] = useState(false);

  const [unidades, setUnidades] = useState([]);

  const [insumosServ, setInsumosServ] = useState(data.insumos);
  const [porcentajeUtilidad, setPorcentajeUtilidad] = useState('1');
  const [msgValidacion, setMsgValidacion] = useState('');
  const [ctrlActivo, setCtrlActivo] = useState('');

  const animatedComponents = makeAnimated();
  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999, fontSize: "0.75em" }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
  };

  const useActiveElement = () => {
    const [active, setActive] = useState(document.activeElement);

    const handleFocusIn = (e) => {
      setActive(document.activeElement);
    }

    useEffect(() => {
      document.addEventListener('focusin', handleFocusIn)
      return () => {
        document.removeEventListener('focusin', handleFocusIn)
      };
    }, [])

    return active;
  }

  const focusedElement = useActiveElement();


  const getClaveServ = async e => {

    if (!editar) {
      setStatus({ status: null, message: "" });
      setLoading(true);

      const request = await Get('getClaveServicio');

      if (request.status === "success") {
        setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });
      } else {
        setStatus(request);
      }

      setLoading(false);
    }

  }


  const getPrecioServ = (insumosServicios, porcUtilidad = null) => {

    console.log("=============================");
    console.log("getPrecioServ");
        
    let sumPUnit = 0;

    if (insumosServicios) {
      sumPUnit = insumosServicios.reduce((acumulador, elemento) => (
        acumulador + parseFloat(elemento.importe)
      ), 0);
    }

    console.log("sumPUnit");
    console.log(sumPUnit);
    
    const porcentUtilidad = porcUtilidad ?? formData.porcentajeUtilidad;
    console.log("porcentUtilidad");
    console.log(porcentUtilidad);

    const utilidadCalc = parseFloat(parseFloat(sumPUnit).toFixed(2) * porcentUtilidad / 100).toFixed(2);

    console.log("utilidadCalc");
    console.log(utilidadCalc);

    const precioNeto = parseFloat(sumPUnit) + parseFloat(utilidadCalc);

    console.log("precioNeto");
    console.log(precioNeto);

    const total = parseFloat((precioNeto).toFixed(2)) * 1.16;

    console.log("getPrecioServ - total");
    console.log(total);

    if (triggerEdit) {
      setFormData({
        ...data,
        precioUnitario: sumPUnit,
        porcentajeUtilidad: porcUtilidad.toString(),
        utilidad: utilidadCalc,
        precioSinIva: precioNeto,
        costoTotal: total,
      })
    }
    else {
      setFormData({
        ...formData,
        precioUnitario: sumPUnit,
        porcentajeUtilidad: porcUtilidad.toString(),
        utilidad: utilidadCalc,
        precioSinIva: precioNeto,
        costoTotal: total,
      })
    }

  };

  const updateUtilidad = (costoTotal) => {

    console.log("===============================================");
    console.log("insumosServ: ");
    console.log(insumosServ);

    if (!insumosServ) {
      return;
    }

    if (insumosServ.length === 0) {
      return;
    }

    console.log("updateUtilidad - costoTotal: ");
    console.log(costoTotal);
    console.log("updateUtilidad - precioUnitario: ");
    console.log(formData.precioUnitario);

    const priceSinIva = parseFloat(parseFloat(costoTotal).toFixed(2) / 1.16).toFixed(2);
    const util = parseFloat(priceSinIva).toFixed(2) - parseFloat(formData.precioUnitario).toFixed(2);
    const porcUtil = (parseFloat((parseFloat(util).toFixed(2) / parseFloat(formData.precioUnitario).toFixed(2)) * 100));

    /*
    if (util < 1) {
      util = 0;
    }

    if (porcUtil < 1) {
      porcUtil = 0;
    }
    */

    console.log("updateUtilidad - priceSinIva: ");
    console.log(priceSinIva);

    console.log("updateUtilidad - util: ");
    console.log(util);

    console.log("updateUtilidad - porcUtil: ");
    console.log(porcUtil);

    setFormData({
      ...formData,
      'precioSinIva': parseFloat(priceSinIva).toFixed(2),
      'utilidad': parseFloat(util).toFixed(2),
      'porcentajeUtilidad': porcUtil.toString(),
      'costoTotal': costoTotal,
    });

  }

  useEffect(() => {

    getClaveServ();

    setArrResponsables(responsables);

    setFormData(data);
    setPermiteEditDesc(data.editar_descripcion === 1);

    setUnidades(unidadesArr);
    setStatus({ status: null, message: "" });
    setInsumosServ(data.insumos);

    if (triggerEdit) {
      // getPrecioServ(data.insumos, (data.insumos?.[0]?.porcentaje_utilidad === undefined) ? 1 : data.insumos[0].porcentaje_utilidad);
      // setPorcentajeUtilidad((data.insumos?.[0]?.porcentaje_utilidad === undefined) ? 1 : data.insumos[0].porcentaje_utilidad);

      console.log(data);
      
      setFormData({
        ...data,                       
        precioSinIva: data.precioSinIva,
        costoTotal:  data.total,
        utilidad: data.utilidad,
      });

    }


  }, [data, setFormData, setPermiteEditDesc, setInsumosServ, setPorcentajeUtilidad]);

  useEffect(() => {

    console.log(" useEffect -> formData.costoTotal");
    console.log(focusedElement.name);

    if (ctrlActivo === 'costoTotal') {
      console.log("formData.costoTotal: ");
      console.log(formData.costoTotal);      
      
      updateUtilidad(formData.costoTotal);
      console.log(focusedElement.value);
    }

  }, [formData.costoTotal]);

  useEffect(() => {

    console.log(" useEffect -> formData.porcentajeUtilidad");
    console.log(focusedElement.name);

    if (ctrlActivo === 'porcUtilidad') {
      console.log(focusedElement.value);
      getPrecioServ(insumosServ, formData.porcentajeUtilidad);
    }

  }, [formData.porcentajeUtilidad]);

  useEffect(() => {
    setCtrlActivo(focusedElement.name);
  }, [focusedElement]);

  const handleChange = (e, name = "", value = "") => {

    if (name === "") {

      if (e.target.name === 'porcUtilidad') {

        const porcentajeUtilidadF = e.target.value;

        if (e.key !== "Delete" && e.key !== "Backspace") {
          e.preventDefault();
        }

        if (e.charCode === 45 ||
          e.charCode === 43 ||
          e.charCode === 45
        ) {
          e.preventDefault();
        }

        setFormData({ ...formData, porcentajeUtilidad: porcentajeUtilidadF.toString() });
        getPrecioServ(insumosServ, porcentajeUtilidadF);
        return;
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [value]: e[value], [name]: e });
    }

    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }

  }

  const onlyNumbers = (e) => {

    if (e.charCode === 45 ||
      e.charCode === 43
    ) {
      e.preventDefault();
    }

    if (!/^\d{0,4}(\.\d{0,4})?$/.test(e.target.value)) {
      e.preventDefault();
    }

  }

  const doRequest = async e => {
    setMsgValidacion('');

    if (porcentajeUtilidad === '0' || porcentajeUtilidad === '') {
      setMsgValidacion('El Porcentaje de Utilidad debe de ser mayor a 0.');
      return;
    }

    setStatus({ status: null, message: "" });
    setLoading(true);

    setFormData(async (prevFormData) => {
      const newFormData = {
        ...prevFormData,
        permiteEditarDesc: permiteEditDesc, 
        insumos: insumosServ, 
        p_unit: prevFormData.precioUnitario,
        precio_sin_iva: prevFormData.precioSinIva,
        costo_total: prevFormData.costoTotal
      };

      const request = editar ? await Put(`servicios/${newFormData.id_servicio}`, newFormData) : await Post('servicios', newFormData);

      if (request.status === "success") {
        setTriggerEdit(false);
        await getLista();

        setShow(true);
      }

      setStatus(request);
      setLoading(false);


      return newFormData;
    });
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  return (

    <MDBox pb={3}>
      <Card mt={4}>

        <MDBox p={3} className="headerFormPymn">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {editar ? "Actualizar" : "Agregar"} Servicio
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2} padding={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              : <>

                <Grid container spacing={4}>
                  <Grid item xs={4} >
                    <MDInput type="text" label="Clave" name="clave"
                      error={verifyError("clave")} errorMsg={getError("clave")}
                      inputProps={{ maxLength: 7, readOnly: true, }}
                      value={formData.clave} onChange={event => handleChange(event)} fullWidth />
                  </Grid>

                  <Grid item xs={4} >
                    <small className="font-red">{getError("id_unidad")}</small>
                    <Select className={`z-10 ${verifyError("id_unidad") ? "border-red" : ""}`}
                      options={tipos} placeholder="Unidad"
                      menuPortalTarget={document.body} styles={selectStyles}
                      value={formData.unidad} onChange={(e) => handleChange(e, "unidad", "id_unidad")}
                      getOptionValue={(option) => `${option.id_unidad}`}
                      getOptionLabel={(option) => `${option.nombre}`}
                    />
                  </Grid>

                  <Grid item xs={4} >
                    <small className="font-red">{getError("id_departamento")}</small>
                    <Select className={`z-10 ${verifyError("id_departamento") ? "border-red" : ""}`}
                      options={departamentos} placeholder="Departamento"
                      menuPortalTarget={document.body} styles={selectStyles}
                      value={formData.departamento} onChange={(e) => handleChange(e, "departamento", "id_departamento")}
                      getOptionValue={(option) => `${option.id_departamento}`}
                      getOptionLabel={(option) => `${option.nombre}`}
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <small className="font-red">{getError("responsables")}</small>
                    <Select className={`z-10 ${verifyError("responsables") ? "border-red" : ""}`}
                      closeMenuOnSelect
                      components={animatedComponents}
                      key={inputKey || ''}
                      isMulti placeholder="* Responsables"
                      value={formData.responsables} onChange={(e) => handleChange(e, "responsables", "arrResponsables")}
                      options={arrResponsables}
                      getOptionValue={(option) => `${option.id}`}
                      getOptionLabel={(option) => `${option.name}`}
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <MDInput multiline type="textarea" pt={5} rows={3} label="Descripcion" name="descripcion"
                      error={verifyError("descripcion")} errorMsg={getError("descripcion")}
                      value={formData.descripcion} onChange={event => handleChange(event)} fullWidth />
                  </Grid>

                  <Grid item xs={12} >
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      // onClick={handleSetPermiteEditDesc}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;¿Permite modificar la descripcion al cotizar?&nbsp;&nbsp;
                    </MDTypography>
                    <Switch checked={permiteEditDesc}
                      onChange={(e) => {

                        if (permiteEditDesc === true) {

                          setFormData({
                            ...formData,
                            'editar_descripcion': 0,
                          });

                          setPermiteEditDesc(false);
                        } else {

                          setFormData({
                            ...formData,
                            'editar_descripcion': 1,
                          });

                          setPermiteEditDesc(true);
                        }

                      }}

                    />
                  </Grid>
                </Grid>

                <Divider className="fullWidth" />

                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} sm={12}>
                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                      Insumos
                    </MDTypography>
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} sm={12}>
                    <ListaInsumosServ
                      setLoading={setLoading}
                      setInsumosServ={setInsumosServ}
                      unidadesArr={unidades}
                      insumosServ={insumosServ}
                      errors={"errors" in status ? status.errors : null}
                      setStatus={setStatus}
                      getPrecioServ={getPrecioServ}
                      porcentajeUtilidad={porcentajeUtilidad}
                      triggerEdit={triggerEdit} />
                  </Grid>
                </Grid>

                <Divider className="fullWidth" />

                <Grid container spacing={4} mt={0}>

                  <Grid container spacing={2}>
                    <Grid xs={5} md={6} />

                    <Grid container item spacing={2} xs={6} md={5}>

                      <Grid item xs={6} md={8} >
                        <p className="txtDetailCost">Precio unitario</p>
                      </Grid>
                      <Grid item xs={6} md={4} style={{ textAlign: 'right' }} >
                        <p className="txtDetailCostValue">
                          {parseCurrency(formData.precioUnitario)}
                        </p>
                      </Grid>

                    </Grid>
                    <Grid md={1} />

                  </Grid>

                  <Grid container spacing={2}>
                    <Grid xs={5} md={6} />

                    <Grid container item spacing={2} xs={6} md={5}>

                      <Grid item xs={6} md={8} pt={5} >
                        <p className="txtDetailCost">Porcentaje de utilidad</p>
                      </Grid>
                      <Grid item xs={6} md={4} style={{ textAlign: 'right' }} >

                        <FormControl variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            className="text-align-input txtResaltado"
                            type="number"
                            label=""
                            name="porcUtilidad"
                            placeholder="1"
                            error={verifyError("porcUtilidad")}
                            errorMsg={getError("porcUtilidad")}
                            value={formData.porcentajeUtilidad}
                            onChange={(e) => {

                              const porcentajeUtilidadF = e.target.value;

                              console.log(porcentajeUtilidadF);
                              console.log(e.charCode);
                              console.log(e.key);

                              if (e.key !== "Delete" && e.key !== "Backspace") {
                                e.preventDefault();
                              }

                              if (e.charCode === 45 ||
                                e.charCode === 43 ||
                                e.charCode === 45
                              ) {
                                e.preventDefault();
                              }

                              setFormData({ ...formData, porcentajeUtilidad: porcentajeUtilidadF.toString() });

                              setPorcentajeUtilidad();

                              setFormData({ ...formData, porcentajeUtilidad: porcentajeUtilidadF });                         
                              // setEditPorcUtil(true);                              
                              // handleChange(values);

                            }}
                          // inputProps={{ max: "1000", min: "1" }}           
                          // onKeyPress={onlyNumbers}
                          />
                        </FormControl>

                      </Grid>

                    </Grid>

                    <Grid md={1} />

                  </Grid>

                  <Grid container spacing={2}>
                    <Grid xs={5} md={6} />

                    <Grid container item spacing={2} xs={6} md={5}>

                      <Grid item xs={6} md={8} >
                        <p className="txtDetailCost">Utilidad</p>
                      </Grid>
                      <Grid item xs={6} md={4} style={{ textAlign: 'right' }} >
                        <p className="txtDetailCostValue">
                          {parseCurrency(formData.utilidad)}
                        </p>
                      </Grid>

                    </Grid>

                    <Grid md={1} />

                  </Grid>
                  <Grid container spacing={2}>
                    <Grid xs={5} md={6} />

                    <Grid container item spacing={2} xs={6} md={5}>

                      <Grid item xs={6} md={8} >
                        <p className="txtDetailCost">Precio sin I.V.A.</p>
                      </Grid>
                      <Grid item xs={6} md={4} style={{ textAlign: 'right' }} >
                        <p className="txtDetailCostValue">
                          {parseCurrency(formData.precioSinIva)}
                        </p>
                      </Grid>

                    </Grid>

                    <Grid md={1} />

                  </Grid>

                  <Grid container spacing={2}>
                    <Grid xs={5} md={6} />

                    <Grid container item spacing={2} xs={6} md={5}>

                      <Grid item xs={6} md={8} >
                        <p className="txtDetailCost txtPorcUtil">* Costo total</p>
                      </Grid>
                      <Grid item xs={6} md={4} style={{ textAlign: 'right' }} >                      
                        <NumericFormat
                          value={parseCurrency(formData.costoTotal)}
                          prefix='$'
                          customInput={MDInput}
                          thousandSeparator=','
                          decimalScale={2}
                          name="costoTotal"                          
                          error={verifyError("costoTotal")}
                          helperText={getError("costoTotal")}
                          className="text-align-input txtResaltado"
                          fullWidth
                          onValueChange={(values) => {
                           
                            const { formattedValue, floatValue, value } = values;

                            console.log("costoTotal: ");
                            console.log(value);
 
                            setFormData({
                              ...formData,
                              'costoTotal': parseFloat(value).toFixed(2)
                            });

                          }}
                        />
                      </Grid>

                    </Grid>

                    <Grid md={1} />

                  </Grid>

                </Grid>

                <Grid container spacing={4}>
                  <Grid item md={4} xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <MDButton disabled={loading}
                      className="btnPaymun"
                      onClick={() => doRequest()} fullWidth >
                      <Icon fontSize="small">done</Icon>
                      &nbsp;{editar ? "Actualizar" : "Agregar"}

                    </MDButton>
                  </Grid>


                  <Grid item md={2} xs={12}>
                    <MDButton
                      onClick={() => setShow(true)}
                      className="btnBackPaymun" fullWidth>
                      <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar
                    </MDButton>
                  </Grid>

                </Grid>

                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <p className="msgWarning">{msgValidacion}</p>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

ServicioForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  tipos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  departamentos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  responsables: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  unidadesArr: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  triggerEdit: PropTypes.bool.isRequired,
  setTriggerEdit: PropTypes.func.isRequired
};

export default ServicioForm;