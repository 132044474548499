/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Tables from "layouts/tables";
import Servicio from "layouts/servicios/Servicio";
import Ocupacion from "layouts/catalogo/Ocupacion";
import PuestosBrigadas from "layouts/catalogo/Brigadas";
import Curso from "layouts/catalogo/Curso";
import Firmante from "layouts/catalogo/Firmante";
import Responsables from "layouts/catalogo/Responsables";
import Evaluaciones from "layouts/evaluaciones";
import ListaOss from "layouts/lista-oss";
import ListaCot from "layouts/lista-coti";
import ListaFac from "layouts/lista-fac";
import ComentariosAdicionales from "layouts/catalogo/Comentarios";
import CondicionesComerciales from "layouts/catalogo/Condiciones";
import Certificados from "layouts/certificados";
import CapturaCertificado from "layouts/certificados/Captura";
import Programacion from 'layouts/programacion'
import Logout from "auth/Logout";
import Usuario from "layouts/usuarios/Usuario";
import DashboardCotizaciones from "layouts/dashboard-cotizaciones"
import UsuarioExterno from "layouts/usuarios-externos/Usuario";

import UnidadesMedidas from "layouts/catalogo/UnidadesMedidas";
import Proveedores from "layouts/catalogo/Proveedores";
import Departamentos from "layouts/catalogo/Departamentos";
import Insumos from "layouts/catalogo/Insumos";

import ReporteExistenciaInventario from "layouts/inventario/reporteExistenciaInventario";
import DistribucionInsumoDepto from "layouts/distribucion-insumo-depto/index";

import Inventario from "layouts/inventario";
import Administracion from "layouts/administracion/index";
import Ubicacion from "layouts/ubicacion/index";

import CarteraClientes from "layouts/cartera-clientes/index";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  /*
   {
     type: "collapse",
     name: "Dashboard",
     key: "dashboard",
     icon: <Icon fontSize="small">dashboard</Icon>,
     route: "/dashboard",
     component: <Dashboard2 />,
   },
   {
     type: "collapse",
     name: "Dashboard",
     key: "dashboard-basico",
     icon: <Icon fontSize="small">equalizer</Icon>,
     route: "/dashboard-basico",
     component: <Dashboard />,
   },
   */
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard-cotizaciones",
    icon: <Icon fontSize="small">equalizer</Icon>,
    route: "/dashboard-cotizaciones",
    component: <DashboardCotizaciones />,
  },
  {
    type: "array",
    name: "Cotizaciones",
    key: "cotizacion",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/captura-cotizacion",
    component: <Tables  />,
    array: [
      {
        typeArr: "collapse",
        nameArr: "Nueva Cotización",
        keyArr: "cotizacion/captura-cotizacion",
        iconArr: <Icon fontSize="small">post_add</Icon>,
        routeArr: "/cotizacion/captura-cotizacion",
        componentArr: <Tables />,
      },
      {
        typeArr: "collapse",
        nameArr: "Cotizaciones generadas",
        keyArr: "cotizacion/lista-cotizacion",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cotizacion/lista-cotizacion",
        componentArr: <ListaCot  />,
      },
      {
        typeArr: "collapse",
        nameArr: "Comentarios adicionales",
        keyArr: "cotizacion/comentarios",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cotizacion/comentarios",
        componentArr: <ComentariosAdicionales  />,
      },
      {
        typeArr: "collapse",
        nameArr: "Condiciones comerciales",
        keyArr: "cotizacion/condiciones",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cotizacion/condiciones",
        componentArr: <CondicionesComerciales  />,
      }
    ],
  },
  {
    type: "array",
    name: "OSS",
    key: "oss",
    icon: <Icon fontSize="small">task</Icon>,
    route: "/captura-oss",
    component: <Tables />,
    array: [
      {
        typeArr: "collapse",
        nameArr: "Nueva OSS",
        keyArr: "oss/captura-oss",
        iconArr: <Icon fontSize="small">post_add</Icon>,
        routeArr: "/oss/captura-oss",
        componentArr: <Tables />,
      },
      {
        typeArr: "collapse",
        nameArr: "OSS generadas",
        keyArr: "oss/lista-oss",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/oss/lista-oss",
        componentArr: <ListaOss />,
      },
      {
        typeArr: "collapse",
        nameArr: "Lista de productos y servicios",
        keyArr: "catalogos/servicios",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/catalogos/servicios",
        componentArr: <Servicio />,
      },
      {
        typeArr: "collapse",
        nameArr: "Responsables de OSS",
        keyArr: "catalogos/responsables",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/catalogos/responsables",
        componentArr: <Responsables />,
      },
    ],
  },
  {
    type: "array",
    name: "Cursos",
    key: "cursos",
    icon: <Icon fontSize="small">model_training</Icon>,
    array: [
      {
        typeArr: "collapse",
        nameArr: "Programación de cursos",
        keyArr: "cursos/programacion-cursos",
        iconArr: <Icon fontSize="small">pending_actions</Icon>,
        routeArr: "/cursos/programacion-cursos",
        componentArr: <Programacion />,
      },
      {
        typeArr: "collapse",
        nameArr: "Cursos registrados",
        keyArr: "cursos/cursos-registrados",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cursos/cursos-registrados",
        componentArr: <Curso />,
      },
      {
        typeArr: "collapse",
        nameArr: "Ocupaciones STPS",
        keyArr: "cursos/ocupaciones-stps",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cursos/ocupaciones-stps",
        componentArr: <Ocupacion />,
      },
      {
        typeArr: "collapse",
        nameArr: "Firmantes",
        keyArr: "cursos/firmantes",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cursos/firmantes",
        componentArr: <Responsables />,
      },
      {
        typeArr: "collapse",
        nameArr: "Evaluaciones",
        keyArr: "cursos/evaluaciones",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cursos/evaluaciones",
        componentArr: <Evaluaciones />,
      },
      {
        typeArr: "collapse",
        nameArr: "Puestos brigadas PC",
        keyArr: "cursos/brigadas-pc",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/cursos/puestos-brigadas-pc",
        componentArr: <PuestosBrigadas />,
      },
      {
        typeArr: "collapse",
        nameArr: "Historial Constancias",
        keyArr: "certificados/lista",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "/certificados/lista",
        componentArr: <Certificados />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Cartera de clientes",
    key: "cartera-clientes",
    icon: <Icon fontSize="small">account_box</Icon>,
    route: "/cartera-clientes",
    component: <CarteraClientes />,
  },  
  {
    type: "array",
    name: "Usuarios",
    key: "usuarios",
    icon: <Icon fontSize="small">people</Icon>,
    array:[
      {
        typeArr: "collapse",
        nameArr: "Usuarios internos",
        keyArr: "usuarios/usuarios-internos",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "usuarios/usuarios-internos",
        componentArr: <Usuario />,
      },
      {
        typeArr: "collapse",
        nameArr: "Usuarios externos",
        keyArr: "usuarios/usuarios-externos",
        iconArr: <Icon fontSize="small">list_alt</Icon>,
        routeArr: "usuarios/usuarios-externos",
        componentArr: <UsuarioExterno />,
      },
    ]
  },
  {
    name: "Lista",
    key: "lista",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/certificados/lista",
    component: <Certificados />,
  },
  {
    name: "Captura",
    key: "captura",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/certificados/captura",
    component: <CapturaCertificado />,
  },
  {
    name: "Programación",
    key: "cursos/programacion-cursos",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/cursos/programacion-cursos",
    component: <Programacion />,
  },
  {
    name: "Nueva Cotización",
    key: "cotizacion/captura-cotizacion",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cotizacion/captura-cotizacion",
    component: <Tables type="cotizacion" />,
  },
  {
    name: "Cotizaciones generadas",
    key: "cotizacion/lista-cotizacion",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cotizacion/lista-cotizacion",
    component: <ListaCot />,
  },
  {
    name: "Facturas generadas",
    key: "facturas/lista-facturas",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/facturas/lista-facturas",
    component: <ListaFac />,
  },
  {
    name: "Comentarios adicionales",
    key: "cotizacion/comentarios",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cotizacion/comentarios",
    component: <ComentariosAdicionales />,
  },
  {
    name: "Condiciones comerciales",
    key: "cotizacion/condiciones",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cotizacion/condiciones",
    component: <CondicionesComerciales />,
  },
  {
    name: "Nueva OSS",
    key: "oss/captura-oss",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/oss/captura-oss",
    component: <Tables type="oss" />,
  },
  {
    name: "OSS generadas",
    key: "oss/lista-oss",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/oss/lista-oss",
    component: <ListaOss />,
  },
  {
    name: "Servicio",
    key: "servicio",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/catalogos/servicios",
    component: <Servicio />,
  },
  {
    name: "Responsables de OSS",
    key: "responsable",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/catalogos/responsables",
    component: <Responsables />,
  },
  {
    name: "Ocupaciones",
    key: "cursos/ocupaciones-stps",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cursos/ocupaciones-stps",
    component: <Ocupacion />,
  },
  {
    name: "Puestos brigadas PC",
    key: "brigadas",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cursos/puestos-brigadas-pc",
    component: <PuestosBrigadas />,
  },
  {
    name: "Historial Constancias",
    key: "certificados/lista",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/certificados/lista",
    component: <Certificados />,
  },
  {
    name: "Cursos",
    key: "cursos-registrados",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cursos/cursos-registrados",
    component: <Curso />,
  },
  {
    name: "Firmantes",
    key: "firmantes",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cursos/firmantes",
    component: <Firmante />,
  },
  {
    name: "Evaluaciones",
    key: "evaluaciones",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/cursos/evaluaciones",
    component: <Evaluaciones />,
  },
  {
    name: "Usuarios interno",
    key: "usuarios/usuarios-internos",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/usuarios/usuarios-internos",
    component: <Usuario />,
  },
  {
    name: "Usuarios externos",
    key: "usuarios/usuarios-externos",
    icon: <Icon fontSize="small">post_add</Icon>,
    route: "/usuarios/usuarios-externos",
    component: <UsuarioExterno />,
  },  
  {
    type: "collapse",
    name: "Administración",
    key: "administracion",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/administracion",
    component: <Administracion />,
  },
  {
    type: "collapse",
    name: "Ubicación",
    key: "ubicacion",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/ubicacion",
    component: <Ubicacion  />,
  },
  {
    type: "array",
    name: "Inventario",
    key: "adminInventario",
    icon: <Icon fontSize="small">inventory</Icon>,
    array: [     
      {
        typeArr: "collapse",
        nameArr: "Administrar",
        keyArr: "inventario/administrar",
        iconArr: <Icon fontSize="small">fact_check</Icon>,
        routeArr: "/inventario/administrar",        
      },
      {
        typeArr: "collapse",
        nameArr: "Distribución",
        keyArr: "inventario/asignacion-insumo-depto",
        iconArr: <Icon fontSize="small">published_with_changes</Icon>,
        routeArr: "/inventario/asignacion-insumo-depto",
        componentArr: <DistribucionInsumoDepto />,
      },
      {
        typeArr: "collapse",
        nameArr: "Reporte",
        keyArr: "inventario/reporte-existencias",
        iconArr: <Icon fontSize="small">picture_as_pdf</Icon>,
        routeArr: "/inventario/reporte-existencias",
        componentArr: <ReporteExistenciaInventario />,         
      },
    ],
  },
  {
    name: "Administrar",
    key: "inventario/administrar",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/inventario/administrar",
    component: <Inventario />,
  },
  {
    name: "Distribución",
    keyArr: "inventario/asignacion-insumo-depto",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    route: "/inventario/asignacion-insumo-depto",
    component: <DistribucionInsumoDepto />,
  },
  {
     name: "Reporte",
     key: "inventario/reporte-existencias",
     icon: <Icon fontSize="small">picture_as_pdf</Icon>,               
     route: "/inventario/reporte-existencias",
     component: <ReporteExistenciaInventario />,     
  },  
  {
    type: "collapse",
    name: "Cerrar Sesión",
    key: "logout",
    icon: <Icon fontSize="small">exit_to_app</Icon>,
    route: "/logout",
    component: <Logout />,
  },

  
];

export default routes;
