/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Icon from "@mui/material/Icon";

import Modal from "react-modal";
import { Get, Del } from "../../tools/Helper";
import EstadoForm from "./EstadoForm";

// TODO:
// _ Paginacion
// _ Validar si el elemento a eliminar ya se está usando

function Estados() {
  const { idEmpresa } = useParams();
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      maxWidth: "600px",
    },
  };

  const emptyForm = {
    clave: "",
    nombre: "",
    pais: "1",
    // id_region: 0,
    // id_cliente: idEmpresa,
    // cliente: "",
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [selectedDelete, setSelectedDelete] = useState({ id: 0, nombre: "", activo: 1 });
  const [empresa, setEmpresa] = useState({ id_cliente: 0, nombreEmpresa: "" });

  const editarButton = (row) => {
    console.log("editar button");

    console.log(row);

    setSelectedData(row);
    setShow(false);
    setEditar(true);
  };

  const crearButton = () => {
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  };

  const getData = async e => {
    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Get(`estado`);

    if (request.status === "success" ||
        request.status === "empty"
    ) {
      setData(request.data);
        setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else {
        setStatus({ status: request.status, message: "Error." });
    }

    setSelectedData(emptyForm);
    setLoading(false);
}

  useEffect(() => {
    getData();
  }, []);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  function openModal(row) {
    console.log(row);

    setIsOpen(true);
    setSelectedDelete({ id: row.id, nombre: row.nombre, deleted_at: row.deleted_at });
  }

  const deleteEstado = async () => {
    closeModal();
    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Del(`estado/${selectedDelete.id}`);

    if (request.status === "success") {
      await getData();
    }

    setStatus(request);
    setShow(true);
    setLoading(false);
  };

  const editButton = (row) => (
    <Tooltip title="Editar">
      <MDButton className="btnPaymun" iconOnly onClick={() => editarButton(row)}>
        <Icon>modeEdit</Icon>
      </MDButton>
    </Tooltip>
  );
  const deleteButton = (row) => (
    <Tooltip title="Desactivar">
      <MDButton className="btnPaymun btnChangeStatus" iconOnly onClick={() => openModal(row)}>
        <Icon>not_interested</Icon>
      </MDButton>
    </Tooltip>
  );
  const activateButton = (row) => (
    <Tooltip title="Activar">
      <MDButton className="btnPaymun btnChangeStatusOn" iconOnly onClick={() => openModal(row)}>
        <Icon>check_box</Icon>
      </MDButton>
    </Tooltip>
  );

  const backEmpresas = () => {
    navigate(`/cartera-clientes`);
  };

  
  const columns = [
    {
      name: "Clave",
      selector: (row) => row.clave,
      compact: true,
      grow: 1,

      
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      wrap: true,
      grow: 3,
    },
    {
      name: "",
      selector: (row) => editButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
    {
      name: "",
      selector: (row) => (row.deleted_at == null ? deleteButton(row) : activateButton(row)),
      grow: 0,
      compact: true,
      width: "60px",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <Grid container xs={12} md={12}>
        <Grid item pb={3} xs={12}>
            <Fade in={show} timeout={800}>
                <Collapse in={show} timeout={50}>
                    <Card>
                        <MDBox 
                            p={3}
                            className="headerFormPymn"
                        >
                            <Grid container spacing={6}>
                                <Grid item xs={9}>
                                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                        Estados
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={3} textAlign="right" >
                                    <MDButton className="btnPaymun btnAdd"
                                        onClick={() => crearButton()}>
                                        <Icon fontSize="small">add</Icon>
                                        &nbsp;Agregar
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox pt={3}>
                            <MDBox px={3}>
                                {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                                    <CircularProgress size="10rem" />
                                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                                </MDBox>
                                    :
                                    <MDBox>
                                        <DataTableExtensions
                                        {...tableData}
                                        export={false}
                                        print={false}
                                        filterPlaceholder="Buscar..."
                                        >
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            pagination
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Estados por página:',
                                                rangeSeparatorText: 'de',
                                                noRowsPerPage: false,
                                                selectAllRowsItem: true,
                                                selectAllRowsItemText: 'Todos'
                                            }}
                                            noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                                        />
                                        </DataTableExtensions>
                                    </MDBox>
                                }
                            </MDBox>
                        </MDBox>
                    </Card>
                </Collapse>
            </Fade>
        </Grid>

        <Grid container xs={12} md={12}>
            <Grid item pb={3} xs={12}>

                <Fade in={!show} timeout={800}>
                    <Collapse in={!show} timeout={50}>
                        <EstadoForm data={selectedData} 
                                    editar={editar} 
                                    setShow={setShow}
                                    getLista={getData}/>
                    </Collapse>
                </Fade>
            </Grid>
       </Grid>

       <Modal
          isOpen={modalIsOpen}
          contentLabel={`¿Seguro que desea ${
            selectedDelete && selectedDelete.deleted_at ? "activar" : "desactivar"
          } el estado ${selectedDelete ? selectedDelete.nombre : ""}?`}
          style={customStyles}
        >
          <Grid container p={1}>
            <Grid item md={12} sm={12}>
              <h2 className="alignCenter txtInfoModal">{`¿Seguro que desea ${
                selectedDelete && selectedDelete.deleted_at ? "activar" : "desactivar"
              } el estado ${selectedDelete ? selectedDelete.nombre : ""}?`}</h2>
            </Grid>
          </Grid>

          <Grid container spacing={2} p={1}>
            <Grid item md={6} sm={12} xs={12} className="alignRigth">
              <MDButton className="btnPaymun" disabled={loading} onClick={() => deleteEstado()}>
                <Icon fontSize="small">done</Icon>
                &nbsp;{selectedDelete && selectedDelete.deleted_at ? "Activar" : "Desactivar"}
              </MDButton>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="alignLeft">
              <MDButton className="btnBackPaymun" disabled={loading} onClick={() => closeModal()}>
                <Icon fontSize="small">arrow_back</Icon>
                &nbsp;Cancelar
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
        <Footer />
        
    </Grid>
);
}


// Setting default props for the ProfileInfoCard
Estados.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
Estados.propTypes = {
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default Estados;
