/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from 'react-modal';
import Tooltip from '@mui/material/Tooltip';

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import makeAnimated from 'react-select/animated';
import PropTypes from 'prop-types';
import Select from 'react-select';

import MapLocation from "../../tools/MapLocation";

import { Post, Put, PostGuest, Get } from "../../tools/Helper";
import { selectStyles, onlyNumbers } from '../../tools/utils';
import Message from "../../tools/Message";

function SucursalForm({ editar, data, setShow, getLista, arrRegiones }) {

  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [listRegimen, setListRegimen] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState(0);
  const [colonias, setColonias] = useState([]);
  const [showMapLocation, setShowMapLocation] = useState(false);
  const [ubicacion, setUbicacion] = useState('');

  const buscarCodigoPostal = async (postalCode) => {

    setStatus({ status: null, message: "" });
    setLoading(true);
    setColonias([]);

    let cp = '';

    if (!postalCode) {
      cp = formData.codigo_postal;
    } else {
      cp = postalCode
    }

    setFormData({
      ...formData,
      estado: '',
      municipio: '',
      id_colonia: 0,
    });

    const request = await Get(`getByPostalCode/${cp}`);

    if (request.status === "success") {

      if (request.data.length > 0) {

        setColonias(request.data);

        setFormData({
          ...formData,
          estado: request.data[0].nombreEstado,
          municipio: request.data[0].nombreMunicipio,
        });

      }

      if (!editar) {
        setStatus({ status: request.status, message: "Se ha realizado la búsqueda del Código Postal exitosamente." });

        setTimeout(() => {
          setStatus({ status: null, message: "" });
        }, 3500);
      }

    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  const getClaveSucursal = async e => {

    if (!editar) {
      setStatus({ status: null, message: "" });
      setLoading(true);

      const request = await Get('getClaveSucursal');

      if (request.status === "success") {
        setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });
      } else {
        setStatus(request);
      }

      setLoading(false);
    }

  }

  useEffect(async () => {

    if (editar) {
      await buscarCodigoPostal(data.codigo_postal);
    }

    getClaveSucursal();
    setFormData(data);
    setStatus({ status: null, message: "" });

  }, [data, setFormData]);

  const handleChange = (e) => {

    if (e.target.name === 'cp_fiscal') {

      setFormData({
        ...formData,
        estado_fiscal: '',
        municipio_fiscal: '',
      });

    }

    setFormData({ ...formData, [e.target.name]: e.target.value });

  }

  const handleChangeSelect = (e, name = "", value = "") => {

    if (value === 'id_colonia') {

      const codigoPostal = colonias.find(option => option.idColonia === e[name]);

      setFormData({
        ...formData,
        colonia: codigoPostal.nombreColonia,
        [value]: e[name]
      });

    } if (value === "id_region") {

      const infoRegion = arrRegiones.find(option => option.id_region === e[name]);

      setFormData({
        ...formData,
        region: infoRegion,
        id_region: e[name]
      });
    } else {
      setFormData({
        ...formData,
        [value]: e[name]
      });
    }

  }

  const doRequest = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = editar ? await Put(`sucursales/${formData.id_sucursal}`, formData) : await Post('sucursales', formData);

    if (request.status === "success") {
      await getLista();
      setStatus(request);
      setLoading(false);
      setShow(true);
      setShowMapLocation(false);
    } else {
      setStatus({
        ...request,
        status: 'error'
      });
    }


    setLoading(false);
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const keyPressCP = (e) => {

    setFormData({
      ...formData,
      estado: '',
      municipio: '',
      id_colonia: 0
    });

    if (e.charCode === 13) {
      buscarCodigoPostal(e.target.value);
    }

    // no permite los caracteres
    // - y +
    if (e.charCode === 45 ||
      e.charCode === 43 ||
      e.charCode === 45
    ) {
      e.preventDefault();
    }

    // si es cero y no hay informacion
    // retorna el evento
    if (e.charCode === 48 && e.target.value.length === 0) {
      e.preventDefault();
    }

    if (e.target.value.length > 4) {
      e.preventDefault();
    }

  };

  const getLocation = (location) => {
    
    if (!location) {
      return;
    }

    setFormData({
      ...formData,
      "latitud": location[0],
      "longitud": location[1],
      "ubicacion": `${location[0]} , ${location[1]}`,
    });

  }

  const getRegion = arrRegiones.find(option => option.id_region === formData.id_region);
  const getCodigoPostal = colonias.find(option => option.idColonia === formData.id_colonia);
  const locationButton = (row) => <Tooltip title="Ubicación"><MDButton className="btnPaymun btnChangeStatus" iconOnly ><Icon>not_interested</Icon></MDButton></Tooltip>;

  return (
    <MDBox pb={3}>
      <Card mt={4}>

        <MDBox p={3} className="headerFormPymn">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {editar ? "Actualizar" : "Agregar"} Sucursal
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2} >
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              :
              <>

                <Grid item xs={2} mt="auto" style={{ display: 'flex', justifyContent: 'center' }} >
                  <MDInput type="text" label="Clave" name="clave" disabled={loading}
                    error={verifyError("clave")}
                    errorMsg={getError("clave")}
                    inputProps={{ readOnly: true }}
                    value={formData.clave} fullWidth
                    required />
                </Grid>

                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Nombre" name="nombre" disabled={loading}
                    error={verifyError("nombre")} errorMsg={getError("nombre")}
                    inputProps={{ maxLength: "150" }}
                    value={formData.nombre} onChange={event => handleChange(event)} fullWidth
                    required />
                </Grid>

                <Grid item xs={4} mt="auto">

                  <Select
                    className={`z-101 ${verifyError("id_region") ? "border-red" : ""}`}
                    options={arrRegiones} placeholder="Región" isDisabled={loading}
                    value={getRegion || ''} onChange={(e) => handleChangeSelect(e, "id_region", "id_region")}
                    getOptionValue={(option) => `${option.id_region}`}
                    getOptionLabel={(option) => `${option.nombre}`}
                  />

                </Grid>

                <Grid item xs={12} mt="auto">
                  <hr className="MuiDivider-root MuiDivider-fullWidth css-4g2ewc-MuiDivider-root" />
                </Grid>

                <Grid item xs={12} mt="auto">
                  Domicilio:
                </Grid>

                <Grid item xs="2" mt="auto" >
                  <Grid container>
                    <Grid item xs={10} >
                      <MDInput type="number" label="Código postal" name="codigo_postal" disabled={loading}
                        error={verifyError("codigo_postal")} errorMsg={getError("codigo_postal")}
                        inputProps={{ maxLength: "5" }}
                        value={formData.codigo_postal} onChange={event => handleChange(event)}
                        onKeyPress={keyPressCP} fullWidth required />
                    </Grid>

                    <Grid item xs={1} mt={.4} >
                      <MDButton className="btnPaymun btnSearch" iconOnly
                        size="medium" onClick={() => buscarCodigoPostal()} >
                        <Icon>search</Icon>
                      </MDButton>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Estado" name="estado" disabled={loading}
                    error={verifyError("estado")} errorMsg={getError("estado")}
                    value={formData.estado} onChange={event => handleChange(event)}
                    inputProps={{ readOnly: true, }} fullWidth />
                </Grid>

                <Grid item xs={3} mt="auto">
                  <MDInput type="text" label="Municipio/Alcaldía" name="municipio" disabled={loading}
                    error={verifyError("municipio")} errorMsg={getError("municipio")}
                    value={formData.municipio} onChange={event => handleChange(event)}
                    inputProps={{ readOnly: true, }} fullWidth />
                </Grid>

                <Grid item xs={4} mt="auto">
                  <Select
                    className={`z-10 ${verifyError("id_colonia") ? "border-red" : ""}`}
                    closeMenuOnSelect
                    menuPortalTarget={document.body} styles={selectStyles}
                    options={colonias} placeholder="Colonia" isDisabled={loading}
                    value={getCodigoPostal || ''} onChange={(e) => handleChangeSelect(e, "idColonia", "id_colonia")}
                    getOptionValue={(option) => `${option.idColonia}`}
                    getOptionLabel={(option) => `${option.nombreColonia}`}
                  />
                </Grid>

                <Grid item xs={8} mt="auto">
                  <MDInput type="text" label="Calle y número" name="calle_numero" disabled={loading}
                    error={verifyError("calle_numero")} errorMsg={getError("calle_numero")}
                    value={formData.calle_numero} onChange={event => handleChange(event)} fullWidth
                    required />
                </Grid>

                <Grid item xs={4} mt="auto" >
                  <Grid container>
                    <Grid item xs={10} >

                      <MDInput type="text" label="Ubicacion" name="ubicacion" disabled={loading}
                        error={verifyError("ubicacion")} errorMsg={getError("ubicacion")}
                        value={formData.ubicacion} inputProps={{ readOnly: true }}
                        fullWidth required />
                    </Grid>

                    <Grid item xs={1} mt={.4} >

                      {
                        !showMapLocation ?
                          <MDButton className="btnPaymun btnSearch" iconOnly
                            size="medium" onClick={() => setShowMapLocation(true)} >
                            <Icon>search</Icon>
                          </MDButton> :
                          <MDButton className="btnPaymun btnChangeStatus" iconOnly
                            size="medium" onClick={() => setShowMapLocation(false)} >
                            <Icon>not_interested</Icon>
                          </MDButton>
                      }

                    </Grid>
                  </Grid>

                </Grid>

                {showMapLocation ?
                  <>
                    <Grid item xs={12} mt="auto">
                      <p>Instrucciones: Arrastre el icono hasta la ubicación de la empresa.</p>
                    </Grid>

                    <Grid item xs={12} mt="auto">
                      <MapLocation setUbicacion={getLocation} />
                    </Grid>

                  </>
                  : null
                }

                <Grid container md={12} spacing={2} mt={1} >

                  <Grid item md={4} >
                    &nbsp;
                  </Grid>
                  <Grid item md={2} >
                    <MDButton disabled={loading}
                      fullWidth
                      className="btnPaymun"
                      onClick={() => doRequest()} >
                      {editar ? "Actualizar" : "Agregar"}
                    </MDButton>
                  </Grid>

                  <Grid item md={2}>
                    <MDButton
                      fullWidth
                      onClick={() => setShow(true)}
                      className="btnBackPaymun" >
                      <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar
                    </MDButton>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
        </MDBox>

      </Card>
    </MDBox>
  );
}

SucursalForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  arrRegiones: PropTypes.bool.isRequired,
};

export default SucursalForm;
