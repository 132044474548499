import React, { useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import { tileLayer } from "./utils"

function LocationMarker({ onMove, pos, setUbicacion }) {
  return (
    <Marker
      position={pos}
      draggable
      autoPan
      eventHandlers={{
        moveend: (event) => {
          setUbicacion([event.target.getLatLng().lat, event.target.getLatLng().lng]);
          onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
        }
      }}
    />
  );
}

LocationMarker.propTypes = {
  pos: PropTypes.bool.isRequired,
  onMove: PropTypes.func.isRequired,
  setUbicacion: PropTypes.func.isRequired,
};

function MapLocation({ setUbicacion }) {

  const [markerPos, setMarkerPos] = useState([18.22935133838668, -93.21624755859376]);

  return (
    <Grid container pt={2}>

      <Grid item height={400} xs={12} mt="auto"  >

        <MapContainer
          center={markerPos}
          zoom={13}
          bounceAtZoomLimits={false}
          style={{ height: "400px", width: "100%" }}
          >
          <TileLayer {...tileLayer} />

          <LocationMarker onMove={setMarkerPos} pos={markerPos} setUbicacion={setUbicacion} />
        </MapContainer>
      </Grid>

    </Grid>
  );
}

MapLocation.propTypes = {
  setUbicacion: PropTypes.func.isRequired,
};
export default MapLocation;
