/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import AddShoppingCartIcon from '@mui/icons-material/Settings';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import Icon from "@mui/material/Icon";

import ClienteForm from "./ClienteForm";
import { Get } from "../../../tools/Helper";
import BoxLinksRegister from "../../../components/BoxLinksRegister";

function AddCliente() {

  const emptyForm = {
    uid: '',
    logo_cliente: '',
    nombre_comercial: '',
    razon_social: '',
    calle_num_inmueble: '',
    municipio_inmueble: '',
    colonia_inmueble: '',
    id_colonia_inmueble: 0,
    estado_inmueble: '',
    calle_num_fiscal: '',
    num_fiscal: '',
    cp_fiscal: '',
    regimen_fiscal: '',
    municipio_fiscal: '',
    colonia_fiscal: '',
    id_colonia_fiscal: 0,
    estado_fiscal: '',
    status: 1,
    rfc: '',
    nombre_patron: '',
    nombre_representante: '',
    cp_inmueble: '',
  };

  const anchorRef = React.useRef < HTMLButtonElement > (null);

  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState(emptyForm);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const btnCancel = useRef(null);
  const [totalRows, setTotalRows] = useState(10);
  const [dataSearch, setdataSearch] = useState([]);  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [idEmp, setIdEmp] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const editarButton = (row) => {
    navigate(`/empresa/${row.id_cliente}`);   
  }

  const showDetail = (row) => {
    navigate(`/detalle-empresa/${row.id_cliente}`);        
  }

  const showResp = () => {
    navigate(`/responsables-empresa/${idEmp}`);
  }

  const showRegiones = () => {    
    navigate(`/regiones-empresa/${idEmp}`);
  }

  const showSucursales = () => {
    navigate(`/sucursales-empresa/${idEmp}`);
  }

  const createEmpresa = () => {
    navigate(`/empresa/`);
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }

    prevOpen.current = open;
  }, [open]);


  const handleClick = (event) => {

    if (event.currentTarget) {
      console.log(event.currentTarget.getAttribute('data-id'));
      setIdEmp(event.currentTarget.getAttribute('data-id'));
    }
    
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const detailButton = (row) => <Tooltip title="Detalle"><MDButton className="btnPaymun btnBlue " iconOnly onClick={() => showDetail(row)}><Icon>content_paste_search</Icon></MDButton></Tooltip>;
  const editButton = (row) => <Tooltip title="Editar"><MDButton className="btnPaymun" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton></Tooltip>;
  
  const adminButton = (row) => <>            
      <Tooltip title="Configuración">
        <IconButton
          className='icoButton'
          data-id={row.id_cliente}
          onClick={handleClick}
          size="large"
          sx={{ ml: 2 }}
          aria-controls={open ? 'admin-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AddShoppingCartIcon className='ico' />          
        </IconButton>
      </Tooltip>
    
    <Menu
      anchorEl={anchorEl}
      id="admin-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'relative',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: { width: '160px' } // <-- The line that does all
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >

      <MenuItem onClick={showRegiones} className='itemFloatMenu'>
        <Icon>workspaces</Icon>
        &nbsp;Regiones
      </MenuItem>
      <MenuItem onClick={showResp} className='itemFloatMenu'>
        <Icon>groupAdd</Icon>
        &nbsp;Responsables
      </MenuItem>
      <MenuItem onClick={showSucursales} className='itemFloatMenu'>
        <Icon>store_mall_directory</Icon>
        &nbsp;Sucursales
      </MenuItem>
    </Menu>
  </>

  const columns = [
    {
      name: 'Nombre Comercial',
      selector: row => row.nombre_comercial,
    },
    {
      name: 'Razón social',
      selector: row => row.razon_social,
    },
    {
      name: '',
      selector: row => detailButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    {
      name: '',
      selector: row => editButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    {
      name: '',
      selector: row => adminButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    /*
    {
      name: '',
      selector: row => regionButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    {
      name: '',
      selector: row => respButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    {
      name: '',
      selector: row => sucButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    */
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  
  const searchServices = async e => {

    if (!search) {
      return;
    }

    setStatus({ status: null, message: "" });
    setLoading(true);
    setdataSearch([]);

    const request = await Get(`searchClients/${search}`);

    if (request.status === "success") {
      setdataSearch(request.data);
      setActiveSearch(true);
      setStatus({ status: request.status, message: "Se ha realizado la búsqueda exitosamente." });
      btnCancel.current?.focus();

    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  const getData = async (page, rowsPerPage1) => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    if (activeSearch) {
      searchServices();
    } else {
      let request;

      if (page) {
        request = await Get(`getListPaginateClients?page=${page}&per_page=${rowsPerPage1}&delay=1`);
      }
      else {
        request = await Get(`getListPaginateClients?page=${currentPage}&per_page=${rowsPerPage}&delay=1`);
      }

      if (request.status === "success") {
        setData(request.data.data);
        setTotalRows(request.data.total);
        setStatus({ status: request.status, message: "Se ha creado exitosamente." });
      } else {
        setStatus({ status: request.status, message: "Error." });
      }
    }
    setSelectedData(emptyForm);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    getData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!data.length) return; // when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    getData();
  }, []);

  const tableData = {
    columns,
    data,
  };

  const buscarButton = () => {
    searchServices();
  }

  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      searchServices();
    }
  };

  const clearSearch = () => {

    setSearch('');
    setActiveSearch(false);

  }

  return (
    <Grid container xs={12} md={12}>

      <Grid item pb={3} xs={12}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={9}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Empresas
                    </MDTypography>
                  </Grid>
                  {/* <BoxLinksRegister titleTop="Liga de registro" type="fisica-moral" colorButton="success" /> */}
                  <Grid item xs={3} textAlign="right" >
                    <MDButton className="btnPaymun btnAdd"
                      onClick={() => createEmpresa()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>

                </Grid>
              </MDBox>

              <MDBox pt={3} px={3}>
                <Grid container>

                  <Grid item xs={2}>

                    <MDInput type="text" label="Buscar" name="buscar_oss"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={handleKeyPress}
                      variant="standard"
                      disabled={activeSearch}
                      fullWidth
                    />
                  </Grid>

                  {!activeSearch ?
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton variant="gradient" className="btnPaymun btnSearch" iconOnly size="medium" onClick={() => buscarButton()}>
                        <Icon>search</Icon>
                      </MDButton>
                    </Grid>
                    :
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton variant="gradient" className="btnPaymun btnSearch btnSearchCancel" iconOnly size="medium" onClick={() => clearSearch()} ref={btnCancel}>
                        <Icon>cancel</Icon>
                      </MDButton>
                    </Grid>
                  }

                  <Grid xs={3}>
                    &nbsp;
                  </Grid>

                  <Grid xs={6}>
                    <BoxLinksRegister titleTop="Liga de registro" type="fisica-moral" colorButton="success" />
                  </Grid>
                </Grid>
              </MDBox >
              {!activeSearch ?

                <MDBox pt={3}>
                  <MDBox px={3}>
                    {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                      <CircularProgress size="10rem" />
                      <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                    </MDBox>
                      :
                      <DataTable
                        pagination
                        paginationServer
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        paginationPerPage={rowsPerPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Clientes por página:',
                          rangeSeparatorText: 'de',
                          noRowsPerPage: false,
                          selectAllRowsItem: true,
                          selectAllRowsItemText: 'Todos'
                        }}
                        noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />
                    }
                  </MDBox>
                </MDBox>
                :
                // Tabla de resultado de busqueda                
                <MDBox pt={3}>
                  <MDBox px={3}>
                    {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                      <CircularProgress size="10rem" />
                      <MDTypography variant="h4" pt={3}>Buscando...</MDTypography>
                    </MDBox> :

                      <DataTable
                        pagination
                        columns={columns}
                        data={dataSearch}
                        progressPending={loading}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Clientes por página:',
                          rangeSeparatorText: 'de',
                          noRowsPerPage: false,
                          selectAllRowsItem: true,
                          selectAllRowsItemText: 'Todos'
                        }}
                        noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}

                      />

                    }
                  </MDBox>
                </MDBox>
              }

            </Card>
          </Collapse>
        </Fade>
      </Grid>

      {/* <Grid container xs={12} md={12}>
        <Grid item pb={3} xs={12}>

          <Fade in={!show} timeout={800}>
            <Collapse in={!show} timeout={50}>
              <ClienteForm data={selectedData} consulta={consulta} editar={editar} setShow={setShow} getLista={getData} isExternal={false} listRegime={listRegime} />
            </Collapse>
          </Fade>
        </Grid>
      </Grid> */}

      <Footer />
    </Grid>

  );
}

export default AddCliente;