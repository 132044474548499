/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { interpolate } from "chroma-js";
// @mui icons
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Icon from "@mui/material/Icon";
import PropTypes, { number } from 'prop-types';
import { Post, Put, Get } from "../../tools/Helper";
import Message from "../../tools/Message";

function MunicipioForm({ editar, data, setShow, getLista, estadosArr}) {
  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [estados, setEstados] = useState([]);  


  
  const getClaveMunicipio = async e => {
    if (!editar) {
      setStatus({ status: null, message: "" });
      setLoading(true);
      const request = await Get('getClaveMunicipio');
      
      if (request.status === "success") {
        console.log(request);
        
        setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });
      } else {
        setStatus(request);
      }
      setLoading(false);
    }
  }
  useEffect(() => {
    setEstados(estadosArr);  
    setFormData(data);
    getClaveMunicipio();
    setStatus({ status: null, message: "" });
  console.log(estadosArr);
  }, [data, setFormData]);
  const handleChange = (e, name = "", value = "") => {
    if (name === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [value]: e[value], [name]: e });
    }
    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }
  const doRequest = async e => {

    setStatus({ status: null, message: "" });  
    setLoading(true); 

    console.log("Datos enviados:", formData);  

    const request = editar
        ? await Put(`municipio/${formData.municipio_id}`, formData)  
        : await Post('municipio', formData);  

    console.log("Respuesta de la solicitud:", request);  

    if (request.status === "success") {
      await getLista();  
      setShow(true);  
    }
    else if (request.status === 'error') {
      setStatus(request);  
    }

    setLoading(false);  
}

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }
    return false;
  }
  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }
    return "";
  }
  const handleChangeSelectResp = (e, name, value) => {
    console.log(e);
    
    setFormData({
      ...formData,
      estado_id: parseInt(e.id, 10),
      estado: e.name 
    });
  };
  
  const getEstado = estadosArr.find(option => option.id === parseInt(formData.estado_id, 10)); 
  return (
    <MDBox pb={3}>
      <Card mt={4}>
        <MDBox 
          p={3}          
          className="headerFormPymn"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {editar ? "Actualizar" : "Agregar"} Municipio
              </MDTypography>
            </Grid>           
          </Grid>
        </MDBox>
        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              : <>
              <Grid item md={2} xs={12} >
                  <MDInput type="text" label="* Clave" name="clave"
                    error={verifyError("clave")} errorMsg={getError("clave")}
                    value={formData.clave} onChange={event => handleChange(event)}
                    inputProps={{ readOnly: true,}}
                    fullWidth />
                </Grid>
                <Grid item md={5} xs={12} mt="auto">
                <MDInput 
                    type="text" 
                    label="* Nombre" 
                    name="nombre" 
                    error={verifyError("nombre")} 
                    errorMsg={getError("nombre")}
                    inputProps={{ maxLength: 50 }}
                    value={formData.nombre} 
                    onChange={event => handleChange(event)} 
                    fullWidth 
                  />
                  </Grid>

                <Grid item md={5} xs={12}>
                <Select
                    className={`z-10 ${verifyError("estado_id") ? "border-red" : ""}`}
                    placeholder="* Estado"
                    value={getEstado || ''} 
                    onChange={(e) => handleChangeSelectResp(e, "estado_id", "id")}  
                    options={estados}
                    getOptionValue={(option) => `${option.id}`}   
                    getOptionLabel={(option) => `${option.name}`}  
                />
                <small className="font-red">{getError("estado_id")}</small>

                </Grid>          
                <Grid item md={4} xs={12}>
                  &nbsp;
                </Grid>
                <Grid item md={2} xs={12}>
                  <MDButton disabled={loading}
                    className="btnPaymun"
                    onClick={() => doRequest()} fullWidth >
                    <Icon fontSize="small">done</Icon>
                    &nbsp;{editar ? "Actualizar" : "Agregar"}
                  </MDButton> 
                </Grid>
                <Grid item md={2} xs={12}>
                  <MDButton
                    onClick={() => setShow(true)}
                    className="btnBackPaymun" fullWidth>
                    <Icon fontSize="small">arrow_back</Icon>
                    &nbsp;Regresar
                  </MDButton>
                </Grid>
              </>
            }
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

MunicipioForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    municipio_id: PropTypes.number,
    clave: PropTypes.string,
    nombre: PropTypes.string,
    estado: PropTypes.string,
    estado_id: PropTypes.number
  }).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  estadosArr: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
};

export default MunicipioForm;
