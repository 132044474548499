/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tooltip from '@mui/material/Tooltip';
import MDButton from "components/MDButton";

import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';

import Icon from "@mui/material/Icon";

import Modal from 'react-modal';
import UserForm from "./UserForm";
import { Get, Del } from "../../tools/Helper";

function Usuario() {

  const { idEmpresa } = useParams();

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState({ id: 0, name: "", activo: 1 });
  const [cliente, setCliente] = useState();
  const [regiones, setRegiones] = useState([]);

  const navigate = useNavigate();

  const emptyForm = {
    name: '',
    email: '',
    password: '',
    correo_personal: '',
    telefono_empresarial: '',
    telefono_celular: '',
    responsable_id: '',
    responsable: [],
    regiones: [],
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const [selectedData, setSelectedData] = useState(emptyForm);

  const editarButton = (row) => {
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  }

  const crearButton = () => {

    const infoCliente = clientes.find((el) => parseInt(el.id_cliente, 10) === parseInt(idEmpresa, 10));

    if (infoCliente !== undefined) {
      emptyForm.cliente = infoCliente;
      emptyForm.id_cliente = idEmpresa;
    }

    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const conditionalRowStyles = [
    {
      when: row => row.activo === 0,
      style: row => ({ backgroundColor: '#ebc5c5' }),
    },
  ];


  const getData = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = (idEmpresa) ? await Get(`getExternalUsersbyClient/${idEmpresa}`) : await Get('external_users');

    if (request.status === "success") {
      const newListUsers = request.data.filter(item => item.id_responsable === null || item.id_responsable === '');
      setData(newListUsers);
      setClientes(request.clientes);
      setRegiones(request.regiones)
      setStatus({ status: request.status, message: "Se ha creado exitosamente." });

      setCliente(request.clientes.filter(item => Number.parseInt(item.id_cliente, 10) === Number.parseInt(idEmpresa, 10))[0]);

    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(() => {

    getData();

  }, []);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete({ id: 0, name: "", activo: 1 });
  }

  function openModal(row) {
    setIsOpen(true);
    setSelectedDelete({ id: row.id, nombre: row.name, activo: row.activo });
  }

  const deleteUser = async () => {
    closeModal();
    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Del(`users/${selectedDelete.id}`);

    if (request.status === "success") {
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const editButton = (row) => <Tooltip title="Editar"><MDButton className="btnPaymun" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton></Tooltip>;
  const deleteButton = (row) => <Tooltip title="Desactivar"><MDButton className="btnPaymun btnChangeStatus" iconOnly onClick={() => openModal(row)}><Icon>not_interested</Icon></MDButton></Tooltip>;
  const activateButton = (row) => <Tooltip title="Activar"><MDButton className="btnPaymun btnChangeStatusOn" iconOnly onClick={() => openModal(row)}><Icon>check_box</Icon></MDButton></Tooltip>;

  const backEmpresas = () => {
    navigate(`/cartera-clientes`);
  }

  const columnsSucursales = [
    {
      name: 'Clave',
      selector: row => row.clave,
      compact: true,
      grow: 0,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
    },
    {
      name: 'Calle y número',
      selector: row => row.calle,
    },
    {
      name: 'Colonia',
      selector: row => row.colonia,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
      compact: true,
      grow: 0,
    }
  ];

  const columns = [
    {
      name: 'Region',
      selector: row => row.regiones.map((reg) => `${reg.nombre}`).join(', '),
      wrap: true
    },
    {
      name: 'Responsable',
      selector: row => row.name,
    },
    {
      name: 'Teléfono empresarial',
      selector: row => row.telefono_empresarial,
    },
    {
      name: 'Teléfono celular',
      selector: row => row.telefono_celular,
    },
    {
      name: 'Correo empresarial',
      selector: row => row.email,
    },
    {
      name: 'Correo personal',
      selector: row => row.correo_personal,
    },
    {
      name: '',
      selector: row => editButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    {
      name: '',
      selector: row => parseInt(row.activo, 10) === 1 ? deleteButton(row) : activateButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
  ];

  const expandedComponent = (infoRow) => {

    console.log(infoRow.data.sucursales);
    
    return <MDBox m={2}  >

      <DataTable
        className="bgkSuc"
        columns={columnsSucursales}
        data={infoRow.data.sucursales}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Sucursales por página:',
          rangeSeparatorText: 'de',
          noRowsPerPage: false,
          selectAllRowsItem: true,
          selectAllRowsItemText: 'Todos'
        }}
        noDataComponent={<MDBox py={5}>No se han encontrado sucursales.</MDBox>}
      /></MDBox>;      

  }

  const tableData = {
    columns,
    data,
  };
 
  return (

    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      { idEmpresa>0 ? "Responsables" : "Usuarios externos" }
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    {
                      (idEmpresa !== undefined) ?
                        <MDButton className="btnPaymun btnAdd mr10"
                          onClick={() => backEmpresas()} >
                          <Icon fontSize="small">account_box</Icon>
                          &nbsp;Empresas
                        </MDButton>
                        :
                        null
                    }

                    <MDButton className="btnPaymun btnAdd"
                      onClick={() => crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" />
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                    :
                    <>
                      {(cliente !== undefined) ?

                        <Grid item xs={12} mt={0} >
                          <MDTypography variant="h5" mt={0} pt={0}>Empresa: {cliente.nombre_comercial}</MDTypography>
                        </Grid>
                        : null}
                      <DataTableExtensions
                        {...tableData}
                        export={false}
                        print={false}
                        filterPlaceholder="Buscar..."
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          expandableRows
                          expandableRowsComponent={expandedComponent}
                          pagination                          
                          paginationComponentOptions={{
                            rowsPerPageText: 'Clientes por página:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: true,
                            selectAllRowsItemText: 'Todos'
                          }}
                          conditionalRowStyles={conditionalRowStyles}
                          noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}

                        />
                      </DataTableExtensions>
                    </>
                  }

                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>


        <Grid container xs={12} md={12}>
          <Grid item pb={3} xs={12}>
            <Fade in={!show} timeout={800}>
              <Collapse in={!show} timeout={50}>
                <UserForm regiones={regiones} idEmpresa={idEmpresa} data={selectedData} editar={editar} setShow={setShow} getLista={getData} clientesArr={clientes} />
              </Collapse>
            </Fade>
          </Grid>
        </Grid>
        
        <Modal
          isOpen={modalIsOpen}
          contentLabel={`¿Seguro que desea eliminar al usuario ${selectedDelete.nombre}?`}
          style={customStyles}
        >

          <Grid container p={1}>
            <Grid item md={12} sm={12}>
              <h2 className='alignCenter txtInfoModal' >¿Seguro que desea {selectedDelete.activo === 1 ? "desactivar" : "reactivar"} el usuario {selectedDelete.nombre}?</h2>
            </Grid>
          </Grid>
          <Grid container spacing={2} p={1}>
            <Grid item md={6} sm={12} xs={12} className='alignRigth' >
              <MDButton className="btnPaymun" disabled={loading}
                onClick={() => closeModal()}>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="alignLeft">
              <MDButton className="btnBackPaymun" disabled={loading}
                onClick={() => deleteUser()}>
                Cambiar status
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      </MDBox>
      <Footer />
    </DashboardLayout>

  );
}

export default Usuario;