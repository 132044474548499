import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Tooltip from '@mui/material/Tooltip';

import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Select from 'react-select';
import { Divider, Icon } from "@mui/material";
import { parseCurrency, onlyNumbers } from "tools/utils";
import { Get } from "../../tools/Helper";

function ListaInsumosServ({ insumosServ, unidadesArr, setInsumosServ, setStatus, getPrecioServ, errors, setLoading, porcentajeUtilidad }) {

  const [unidades, setUnidades] = useState([]);
  const [insumosArr, setInsumosArr] = useState([]);

  const [unidadSelected, setUnidadSelected] = useState();
  const [insumoSelected, setInsumoSelected] = useState();
  const [cantidadInsumo, setCantidadInsumo] = useState();
  const [msjError, setMsjError] = useState('');

  const [insumo, setInsumo] = useState({
    cantidad: '',
    servicio: []
  });

  function randomString(longitud) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';

    for (let i = 0; i < longitud; i += 1) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(indiceAleatorio);
    }

    return id;
  }

  const esValidoAgregarInsumo = () => {

    let msgError = 'No es posible agregar el insumo, favor de seleccionar todos los campos requeridos';    

    if (insumoSelected === undefined ||
      cantidadInsumo === undefined) {      
      setMsjError(msgError);
      return false;
    }

    const insumoExist = insumosServ.find(ins => ins.id_insumo === insumoSelected.id_insumo);        

    if (insumoExist) {
      msgError = 'Ya existe un insumo con esos valores, por favor revise y corrija la información.'
      setMsjError(msgError);
      return false;
    }

    if (insumoSelected &&
      (cantidadInsumo.toString().length > 0 && parseInt(cantidadInsumo, 10) > 0)) {        
      return true;
    }

    setMsjError(msgError);
    return false;

  }

  const add = () => {

    if (!esValidoAgregarInsumo()) {
      return;
    }
    
    const importeInsumo =  parseFloat(parseFloat(insumoSelected.precio).toFixed(2) * parseInt(cantidadInsumo, 10)).toFixed(2);

    setInsumosServ((prevInsumosServ) => {
      const newInsumosServ = [
        ...prevInsumosServ,
        {
          ...insumoSelected,
          unidad_medida: unidadSelected.nombre,
          cantidad: cantidadInsumo,
          importe: importeInsumo,
          id_element: randomString(8),
        }
        ];

        getPrecioServ(newInsumosServ, porcentajeUtilidad);

        return newInsumosServ;
    });
            
    setCantidadInsumo(0);
    setInsumoSelected('');
    setUnidadSelected('');
    setInsumosArr([]);

  }

  const remove = (idElement) => {    
    
    const newData = insumosServ.filter(item => item.id_element !== idElement);
    
    setInsumosServ(newData);
    getPrecioServ(newData, porcentajeUtilidad);

  }

  const getInsumosByUnidad = async (event) => {

    setMsjError('');
    setInsumosArr([]);
    setInsumoSelected('');
    setStatus({ status: null, message: "" });
    setUnidadSelected(event);

    const request = await Get(`getInsumosByUnidad/${event.id_unidadmedida}`);

    if (request.status === "success") {
      setInsumosArr(request.data);
    } else {
      setStatus({ status: request.status, message: "Error." });
    }


  }

  /*
  const handleChange = (event, index, name = "", value = "") => {

    
    if (name === "servicio") {
      setServicio({
        ...servicio,
        [name]: event,
      });
    } else {
      setServicio({ ...servicio, [event.target.name]: event.target.value });
    }

    if (errors !== null) {
      const errName = name === "" ? `servicios.${index}.${event.target.name}` : `servicios.${index}.${name}`;
      if (errName in errors) {
        const temp = errors;
        delete temp[errName];
        setStatus(status => ({ ...status, errors: temp }));
      }
    }
  }
  */

  const verifyError = (name, index) => {
    if (errors !== null) {
      const errName = `servicios.${index}.${name}`;
      
      if (errName in errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name, index) => {
    if (errors !== null) {
      const errName = `servicios.${index}.${name}`;
      
      if (errName in errors) {
        return errors[errName][0];
      }
    }

    return "";
  }
  
  const updateInsumo = (e) => {
    setInsumoSelected(e);
    setMsjError('');
  }

  const updateCantidad = (e) => {
    if (e.target.value.split("").length > 3) {
      e.target.value = e.target.value.slice(0, 3)
    }
    if (!/[0-9]/.test(e.key) && e.key !== "Delete" && e.key !== "Backspace") {
        e.preventDefault();
    }

    e.target.value = e.target.value.replace(/^0+/, '');

    setCantidadInsumo(e.target.value);
    setMsjError('');
  }

  useEffect(() => {

    setInsumosServ(insumosServ === undefined ? [] : insumosServ);
    setUnidades(unidadesArr);
    setCantidadInsumo(1);

  }, [getPrecioServ])

  return (
    <Fade in timeout={800}>
      <Grid container spacing={2} p={2}>

        <Grid item xs={4} mt="auto">
          <MDBox mb={2}>
            <small className="font-red">{getError("unidad")}</small>
            <Select className={`z-10 z-auto ${verifyError("unidad") ? "border-red" : ""}`}
              options={unidades} placeholder="* Unidad"
              value={unidadSelected}
              onChange={(e) => getInsumosByUnidad(e)}
              getOptionValue={(option) => `${option.id_unidadmedida}`}
              getOptionLabel={(option) => `${option.nombre}`}
            />
          </MDBox>
        </Grid>

        <Grid item xs={5} mt="auto">
          <MDBox mb={2}>
            <small className="font-red">{getError("insumo")}</small>
            <Select className={`z-10 z-auto ${verifyError("insumo") ? "border-red" : ""}`}
              options={insumosArr} placeholder="* Insumo"
              value={insumoSelected}
              onChange={(e) => updateInsumo(e)}
              getOptionValue={(option) => `${option.id_insumo}`}
              getOptionLabel={(option) => `${option.nombre}`}
            />
          </MDBox>
        </Grid>

        <Grid item xs={2}>
          <MDBox mb={2}>
            <MDInput type="number" label="Cantidad" name="cantidad"
              error={verifyError("cantidad")} errorMsg={getError("cantidad")}
              inputProps={{ max: "999", min: "1" }}
              onKeyPress={onlyNumbers}
              value={cantidadInsumo}
              onChange={(e) => updateCantidad(e)} fullWidth
            />
          </MDBox>
        </Grid>

        <Grid item xs={1} mt="auto">
          <MDBox mb={2}>
            <Tooltip title="Agregar">
              <MDButton 
                className="btnPaymun" 
                iconOnly 
                onClick={() => add()} >
                <Icon fontSize="small">add</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
        </Grid>

        <Grid item xs={12} mt="auto">
          <MDBox>
            <p className="msgWarning">
              <b>{msjError}</b>
            </p>
          </MDBox>
        </Grid>

        <hr style={{ flexShrink: 0, opacity: 0.25, BorderStyle: 'solid', borderWidth: 0, backgroundColor: '#121212', borderColor: 'rgba(0, 0, 0, 0.12)', width: '100%', height: '0.0625rem', margin: '1rem 0', borderBottom: 'none' }} />

        <Grid container spacing={1}>

          <Grid item xs={3}>
            <MDBox mb={1}>
              <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center' }}>
                <b>Descripción</b>
              </p>
            </MDBox>
          </Grid>
          <Grid item md={2}>
            <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center' }}>
              <b>Unidad</b>
            </p>
          </Grid>
          <Grid item xs={2} alignItems="center">
            <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center' }}>
              <b>Cantidad</b>
            </p>
          </Grid>
          <Grid item xs={2} alignItems="center">
            <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center' }}>
              <b>P.U.</b>
            </p>
          </Grid>
          <Grid item xs={2} alignItems="center">
            <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center' }}>
              <b>Importe</b>
            </p>
          </Grid>
          <Grid item xs={1} mt="auto">
            <MDBox mb={2} />
          </Grid>

        </Grid>

        <hr style={{ flexShrink: 0, opacity: 0.25, BorderStyle: 'solid', borderWidth: 0, backgroundColor: '#121212', borderColor: 'rgba(0, 0, 0, 0.12)', width: '100%', height: '0.0625rem', margin: '1rem 0', borderBottom: 'none' }} />

        {
          insumosServ ?
            insumosServ.map((item, index) => (
              <Grid container spacing={1}>

                <Grid item xs={3}>
                  <MDBox mb={1}>
                    <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, }}>
                      {item.nombre}
                    </p>
                  </MDBox>
                </Grid>
                <Grid item md={2}>
                  <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, }}>
                    {item.unidad_medida}
                  </p>
                </Grid>

                <Grid item md={2} alignItems="center">
                  <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center' }}>
                    {item.cantidad}
                  </p>
                </Grid>
                <Grid item xs={2} alignItems="center">
                  <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right' }}>
                    {parseCurrency(parseFloat(item.precio).toFixed(2))}
                  </p>
                </Grid>

                <Grid item xs={2} alignItems="center">
                  <p style={{ fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right' }}>
                    {parseCurrency(parseFloat(item.importe).toFixed(2))}
                  </p>
                </Grid>

                <Grid item xs={1} mt="auto">
                  <MDBox mb={2}>
                    
                    <Tooltip title="Eliminar">
                      <MDButton                         
                        className="btnPaymun btnChangeStatus" 
                        iconOnly 
                        onClick={() => remove(item.id_element)}>
                        <Icon>deleteForever</Icon>
                      </MDButton>
                    </Tooltip>

                  </MDBox>
                </Grid>

              </Grid>
            ))
            : null
        }
      </Grid>
    </Fade >);
}

ListaInsumosServ.propTypes = {
  insumosServ: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  unidadesArr: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setInsumosServ: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  getPrecioServ: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  porcentajeUtilidad: PropTypes.string.isRequired,
};

export default ListaInsumosServ;