/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { interpolate } from "chroma-js";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes, { number } from 'prop-types';

import { Post, Put, Get } from "../../tools/Helper";
import Message from "../../tools/Message";


function MigracionSucForm({ editar, data, setShow, getLista, empresa }) {

  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [sucursales, setSucursales] = useState([]);  

  const getClaveRegion = async e => {

    if (!editar) {
      setStatus({ status: null, message: "" });
      setLoading(true);

      const request = await Get('getClaveRegion');

      if (request.status === "success") {
        setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });
      } else {
        setStatus(request);
      }

      setLoading(false);
    }

  }

  useEffect(() => {
    setFormData({
      ...data,

    });

    getClaveRegion();
    // setSucursales(data.sucursales);
    setStatus({ status: null, message: "" });

  }, [data, setFormData]);

  const handleChange = (e, name = "", value = "") => {

    if (name === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [value]: e[value], [name]: e });
    }

    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const doRequest = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    // setFormData({ ...data, 'id_cliente': empresa.id_cliente});

    console.log(formData);

    const request = editar ? await Put(`region/${formData.id_region}`, formData) : await Post('region', formData);

    if (request.status === "success") {
      await getLista();
      setShow(true);
    }
    else if (request.status === 'error') {
      setStatus(request);
    }
    setLoading(false);

  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const handleChangeSelectResp = (e, name, value) => {

    setFormData({
      ...formData,
      'responsable': e,
      "id_responsable": e.id
    });
  }

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
      compact: true,
      grow: 0,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
    },
    {
      name: 'Calle y número',
      selector: row => row.calle,
    },
    {
      name: 'Colonia',
      selector: row => row.colonia,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
      compact: true,
      grow: 0,
    }
  ];

  return (
    <MDBox pb={3}>
      <Card mt={4}>
        <MDBox
          p={3}
          className="headerFormPymn">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                Migrar sucursales
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              : <>

                <Grid item md={3} xs={12} >
                <Select
                    className={`z-10 ${verifyError("id") ? "border-red" : ""}`}
                    placeholder="* Region origen"
                    // value={""} 
                    onChange={(e) => handleChangeSelectResp(e, "id", "id")}  
                    // options={estados}
                    getOptionValue={(option) => `${option.id}`}   
                    getOptionLabel={(option) => `${option.name}`}  
                />
                </Grid>

                <Grid item md={9} xs={12} mt="auto">
                <Select
                    className={`z-10 ${verifyError("id") ? "border-red" : ""}`}
                    placeholder="* Región destino"
                    // value={""} 
                    onChange={(e) => handleChangeSelectResp(e, "id", "id")}  
                    // options={estados}
                    getOptionValue={(option) => `${option.id}`}   
                    getOptionLabel={(option) => `${option.name}`}  
                />
                </Grid>

                {// sucursales !== undefined &&
                  // sucursales.length > 0 && editar ?
                  <>
                    <Grid item xs={12} mt="auto" display="block" fullWidth >
                      <MDTypography variant="h6" pt={3}>Sucursales asignadas</MDTypography>
                    </Grid>
                    <Grid item xs={12} mt="auto">
                      <DataTable
                        columns={columns}
                        // data={sucursales}
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: 'Sucursales por página:',
                          rangeSeparatorText: 'de',
                          noRowsPerPage: false,
                          selectAllRowsItem: true,
                          selectAllRowsItemText: 'Todos'
                        }}
                        noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />
                    </Grid>
                  </>
                  // : null
                }

                <Grid item md={4} xs={12}>
                  &nbsp;
                </Grid>
                <Grid item md={2} xs={12}>
                  <MDButton disabled={loading}
                    className="btnPaymun"
                    onClick={() => doRequest()} fullWidth >
                    <Icon fontSize="small">done</Icon>
                    &nbsp;{editar ? "Actualizar" : "Migrar"}

                  </MDButton>
                </Grid>
              </>
            }
          </Grid>
        </MDBox>

      </Card>

    </MDBox>
  );
}

MigracionSucForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  empresa: PropTypes.string.isRequired,
};

export default MigracionSucForm;