/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';

import { Link } from "react-router-dom";

import Icon from "@mui/material/Icon";

import ServicioForm from "./ServicioForm";
import { Get } from "../../tools/Helper";
import Message from "../../tools/Message";
import { parseCurrency, floorFigure } from '../../tools/utils';

function Servicio() {

  const emptyForm = {
    clave: '',
    clave_prod_sat: '',
    descripcion: '',
    unidad: [],
    departamento: [],
    p_unit: 0,
    proveedores: '',
    editar_descripcion: false,
    precioUnitario: 0,
    porcentajeUtilidad: 0,
    utilidad: 0,
    precioSinIva: 0,
    costoTotal: 0,
  };


  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  // const [selectedData, setSelectedData] = useState(emptyForm);
  const [tipoServicios, setTipoServicios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [unidadesInsumos, setUnidadesInsumos] = useState([]);
  const [triggerEdit, setTriggerEdit] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const btnCancel = useRef(null);
  const [totalRows, setTotalRows] = useState(10);
  const [dataSearch, setdataSearch] = useState([]);

  const editarButton = (row) => {
    setSelectedData(row);
    setShow(false);
    setEditar(true);
    setTriggerEdit(true);
  }

  const crearButton = () => {
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
    setTriggerEdit(false);
  }

  const editButton = (row) => <MDButton variant="gradient"
    color="info" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const questionButton = (id) => <Link to={`/catalogos/servicios/${id}/acciones`}>
    <MDButton variant="gradient" color="info" iconOnly>
      <Icon>check_box</Icon>
    </MDButton>
  </Link>;

  const showSheets = (id) => <Link to={`/catalogos/servicios/${id}/fichas`}>
    <MDButton variant="gradient" color="info" iconOnly>
      <Icon>upload_file</Icon>
    </MDButton>
  </Link>;

  const getPrice = (total) => {

    if (total.toString().indexOf(".") > 0) {
      return total.toString().substring(0, total.toString().indexOf(".") + 2)
    }

    return total;

  }

  const getPrice2 = (serv) => {

    let precio = 0;
    let porcUtil = 0;

    if (serv.id_servicio === 2804) {
      console.log("====================================================");
      console.log(`clave ${serv.clave}`)
      console.log(`Total insumos ${serv.insumos.length}`)
    }

    serv.insumos.forEach(insumo => {
      const precioInsumo = Number(insumo.importe);
      precio = (Number(precio) + precioInsumo);
    });

    porcUtil = serv.porcentajeUtilidad ? serv.porcentajeUtilidad : 0;

    if (serv.id_servicio === 2804) {
      console.log("precio: ");
      console.log(precio);
    }

    const utilidad = parseFloat(precio) * (parseFloat(porcUtil) / 100);

    let total = parseFloat(precio) + parseFloat(utilidad);

    if (serv.id_servicio === 2804) {

      console.log("porcUtil: ");
      console.log(porcUtil);

      console.log("porcUtil/100: ");
      console.log(parseFloat(porcUtil / 100));

      console.log("parseFloat(precio).toFixed(2): ");
      console.log(parseFloat(precio).toFixed(2));

      console.log("porcUtil: ");
      console.log(porcUtil);

      console.log("Utilidad: ");
      console.log(utilidad);

      console.log("precio sin IVA: ");
      console.log(total);

    }

    total *= 1.16;

    if (serv.id_servicio === 2804) {
      console.log("total con IVA: ");
      console.log(floorFigure(total, 2));
    }

    return parseCurrency(floorFigure(total, 2));

  }

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
      grow: 1,
      sortable: true,
    },
    {
      name: 'Descripción',
      selector: row => row.descripcion,
      wrap: true,
      grow: 5,
      sortable: true,
    },
    {
      name: 'Unidad',
      selector: row => row.unidad.nombre,
      grow: 1,
    },
    {
      name: 'Precio',
      selector: row => parseCurrency(row.total),
      grow: 1,
    },
    {
      name: '',
      selector: row => editButton(row),
      grow: 0,
      compact: true,
    },
    {
      name: '',
      selector: row => showSheets(row.id_servicio),
      grow: 0,
      compact: true,
    },
    {
      name: '',
      selector: row => questionButton(row.id_servicio),
      grow: 0,
      compact: true,
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchServices = async e => {

    if (!search) {
      return;
    }

    setStatus({ status: null, message: "" });
    setLoading(true);
    setdataSearch([]);

    const request = await Get(`searchServices/${search}`);

    if (request.status === "success") {
      setdataSearch(request.data);
      setTipoServicios(request.tipos);
      setDepartamentos(request.departamentos);
      setResponsables(request.responsables);
      setUnidadesInsumos(request.unidadesInsumos);

      setActiveSearch(true);
      setStatus({ status: request.status, message: "Se ha realizado la búsqueda exitosamente." });
      btnCancel.current?.focus();

    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  const getData = async (page, rowsPerPage1) => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    if (activeSearch) {
      searchServices();
    } else {

      let request;

      if (page) {
        request = await Get(`getListPaginateServices?page=${page}&per_page=${rowsPerPage1}&delay=1`);
      }
      else {
        request = await Get(`getListPaginateServices?page=${currentPage}&per_page=${rowsPerPage}&delay=1`);
      }

      if (request.status === "success") {

        setData(request.data.data);

        setTotalRows(request.data.total);
        setTipoServicios(request.tipos);
        setDepartamentos(request.departamentos);
        setResponsables(request.responsables);
        setUnidadesInsumos(request.unidadesInsumos);

        setStatus({ status: request.status, message: "Se ha cargado la información exitosamente." });
      } else {
        setStatus({ status: request.status, message: "Error." });
      }
    }

    setSelectedData(emptyForm);
    setLoading(false);

  }

  useEffect(() => {
    getData(1, 10);

    setStatus({ status: null, message: "" });
  }, [activeSearch]);

  const tableData = {
    columns,
    data,
  };

  const handlePageChange = (page) => {
    getData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!data.length) return; // when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const buscarButton = () => {
    searchServices();
  }

  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      searchServices();
    }
  };

  const clearSearch = () => {
  
    setSearch('');
    setActiveSearch(false);
    
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Catálogo de Servicios
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton className="btnPaymun btnAdd"
                      onClick={() => crearButton()}
                      disabled={loading}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3} px={3}>
                <Grid container>

                  <Grid item xs={2}>

                    <MDInput type="text" label="Buscar" name="buscar_oss"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={handleKeyPress}
                      variant="standard"
                      disabled={activeSearch}
                      fullWidth
                    />
                  </Grid>

                  {!activeSearch ?
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton  className="btnPaymun btnSearch" iconOnly size="medium" onClick={() => buscarButton()}>
                        <Icon>search</Icon>
                      </MDButton>
                    </Grid>
                    :
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton  className="btnPaymun btnSearch btnSearchCancel"  iconOnly size="medium" onClick={() => clearSearch()} ref={btnCancel}>
                        <Icon>cancel</Icon>
                      </MDButton>
                    </Grid>
                  }
                </Grid>
              </MDBox >


              {!activeSearch ?

                <MDBox pt={3}>
                  <MDBox px={3}>
                    {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                      <CircularProgress size="10rem" />
                      <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                    </MDBox> :

                      <DataTable
                        pagination
                        paginationServer
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        paginationPerPage={rowsPerPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        paginationComponentOptions={{
                          rowsPerPageText: 'OSS por página:',
                          rangeSeparatorText: 'de',
                          noRowsPerPage: false,
                          selectAllRowsItem: false,
                        }}
                        noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />

                    }
                  </MDBox>
                </MDBox>
                :
                // Tabla de resultado de busqueda                
                <MDBox pt={3}>
                  <MDBox px={3}>
                    {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                      <CircularProgress size="10rem" />
                      <MDTypography variant="h4" pt={3}>Buscando...</MDTypography>
                    </MDBox> :

                      <DataTable
                        pagination
                        columns={columns}
                        data={dataSearch}
                        progressPending={loading}
                        paginationComponentOptions={{
                          rowsPerPageText: 'OSS por página:',
                          rangeSeparatorText: 'de',
                          noRowsPerPage: false,
                          selectAllRowsItem: false,
                        }}
                        noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                      />

                    }
                  </MDBox>
                </MDBox>
              }

            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show} timeout={50}>
            <ServicioForm
              data={selectedData}
              editar={editar}
              setShow={setShow}
              getLista={getData}
              tipos={tipoServicios}
              departamentos={departamentos}
              responsables={responsables}
              unidadesArr={unidadesInsumos}
              triggerEdit={triggerEdit}
              setTriggerEdit={setTriggerEdit}
            />
          </Collapse>
        </Fade>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Servicio;