import { useState, useEffect } from "react";

// leaflet maps
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import { tileLayer } from "../../tools/utils"

function MapRecenter({ lat, lng, zoomLevel }) {
    const map = useMap();

    useEffect(() => {
        // Fly to that coordinates and set new zoom level
        map.flyTo([lat, lng], zoomLevel);
    }, [lat, lng, zoomLevel]);
    return null;
};

function SucursalesMap({ arrSucursales, height }) {


    const [latitud, setLatitud] = useState(parseFloat(17.829289));
    const [longitud, setLongitud] = useState(parseFloat(-92.921646));
    const [centerMap, setCenterMap] = useState([parseFloat(17.829289), parseFloat(-92.921646)]);
    const [showMap, setShowMap] = useState(true);

    const [position, setPosition] = useState({ lat: 98.699739, lng: 52.338097 }); // set default position

    function getMiddle(prop, markers) {
        let values = markers.map(m => m[prop]);
        let min = Math.min(...values);
        let max = Math.max(...values);
        if (prop === 'lng' && (max - min > 180)) {
            values = values.map(val => val < max - 180 ? val + 360 : val);
            min = Math.min(...values);
            max = Math.max(...values);
        }
        let result = (min + max) / 2;
        if (prop === 'lng' && result > 180) {
            result -= 360
        }
        return result;
    }

    function findCenter(markers) {
        return {
            lat: getMiddle('lat', markers),
            lng: getMiddle('lng', markers)
        }
    }

    const MyMarkers = ({ info }) => info.map(({ lat, lng, title }) => (
        <Marker
            key={`${lat}-${lng}`}
            // icon= {defaultIcon}
            position={{ lat, lng }}
        >
            <Popup>{title}</Popup>
        </Marker>
    ))

    useEffect(() => {

        console.log("arrSucursales", arrSucursales);
        console.log("arrSucursales", arrSucursales.length);

        if (arrSucursales) {

            if (arrSucursales.length === 0) {
                setShowMap(false);
            }
            else if (arrSucursales.length === 1) {
                console.log("arrSucursales[0].lat", arrSucursales[0].lat);
                console.log("arrSucursales[0].lng", arrSucursales[0].lng);

                setLatitud(parseFloat(arrSucursales[0].lat));
                setLongitud(parseFloat(arrSucursales[0].lng));        
            } else {
                const centerM = findCenter(arrSucursales);
                console.log('centerM', centerM);
    
                setLatitud(parseFloat(centerM.lat));
                setLongitud(parseFloat(centerM.lng));
            } 
        }

    }, [arrSucursales]);


    return (

        (showMap) ?
        <MapContainer
            style={{ height: "400px", width: "100%" }}
            center={centerMap}
            zoom={10}
            height={height}
            scrollWheelZoom>

            <TileLayer {...tileLayer} />
            <MyMarkers info={arrSucursales} />
            <MapRecenter lat={latitud} lng={longitud} zoomLevel={10} />

        </MapContainer>
        : 
        <p className="infoEmptyMap">No existen sucursales con ubicación disponible</p>

    );

}

SucursalesMap.propTypes = {
    height: PropTypes.number.isRequired,
    arrSucursales: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

export default SucursalesMap;


